/**
 * Created by r4zi4l on 20.08.2021
 */

Families.initialize = function () {
    Families.initializeBase();

    DeletedFamilies.forEach(function (code) {
        delete Families[code];
        delete Families[code + "chest"];
        delete Families[code + "source"];
        delete Families[code + "generator"];
    });

    Families.barrel.units.splice(1, Families.barrel.units.length);

    Families.kingfood = FamiliesHelper.HeroFoodFamily();
    Families.dwarffood = FamiliesHelper.HeroFoodFamily();

    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
