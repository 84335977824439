/**
 * Created by Andrey Popov on 12/9/20.
 */

import _ from "lodash";

import axios from "axios";
import config from "./config";
import utils from "./utils";
import router from "./router/index";
import createHttp from "./http";

const fetchDialogues = ({ commit, state }) => {
    commit("DIALOGUES_FETCHING");

    var games = getGames();
    var folder = router.currentRoute.value.params.folder.split(":")[0];
    var payersOnly = false;
    if (folder.startsWith("$")) {
        folder = folder.replace("$", "");
        payersOnly = true;
    }
    
    return createHttp(state.token).get(config.apiUrl + "/common/messages", {
        params: {
            folder: folder,
            payersOnly: payersOnly,
            enOnly: false,
            filter: state.query,
            reqCount: state.page.selected,
            offset: games.length === 1 ? state.page.selected * state.page.size : undefined,
            limit: games.length === 1 ? state.page.size : undefined,
            tags: state.filter.byTag,
            languages: state.filter.byLanguage,
            statuses: state.filter.byStatus,
            sources: state.filter.bySource,
            respondingPersons: state.filter.byRespondingPerson,
            unreadOnly: state.filter.unreadOnlyMode,
            withAnswers: state.filter.withAnswers,
            latestVersion: state.filter.latestVersion,
            from: state.filter.byDate.from,
            to: state.filter.byDate.to,
            episodes: state.filter.byEpisode,
            levels: state.filter.byLevel,
            projects: games
        }
    }).then(function (results) {
        var allDialogues = results.data.map((dialogue) => {
            dialogue.game = dialogue.projectId;
            if (!dialogue.userId) {
                dialogue.userId = dialogue.id;
            }
            dialogue.id = utils.createDialogueId(dialogue.name, dialogue.dialogueId);
            return dialogue;
        });
        allDialogues.sort(function (a, b) {
            return new Date(b.postDate) - new Date(a.postDate);
        });
        commit("DIALOGUES_FETCHED", allDialogues);
    }).catch(async (error) => {
        if (error.response && error.response.status === 403) {
            localStorage.removeItem("token");
            await router.push({ path: "/login" });
        } else {
            throw error;
        }
    });
};

const fetchUserCard = ({ dispatch, commit, state }, data) => {
    commit("USER_CARD_FETCHING", data.dialogueId === undefined);

    createHttp(state.token).post(config.apiUrl + "/common/getOrCreateDialogue", {
        userId: data.userId,
        dialogueId: data.dialogueId,
        projectId: getGames()[0]
    })
        .then((resp) => {
            if (state.zen && state.zen.active && state.zen.userIds.indexOf(data.userId) !== -1 && !data.stayOnCard) {
                var messages = resp.data.messages;
                if (messages && messages.length > 0 && ["new", "renewal", "pending"].indexOf(messages[messages.length - 1].status) === -1) {
                    dispatch("zenCardProcessed", {
                        userid: data.userId,
                        game: getGames()[0]
                    });
                    return;
                }
            }

            commit("USER_CARD_FETCHED", resp.data);
        }).catch(async (error) => {
            if (error.response && error.response.status === 403) {
                localStorage.removeItem("token");
                await router.push({ path: "/login" });
            } else {
                throw error;
            }
        });
};

const fetchUserSummary = ({ commit, state }, userId) => {
    createHttp(state.token).get(config.apiUrl + "/common/getOrCreateDialogue/", {
        userId: userId,
        projectId: getGames()[0]
    }).then((resp) => {
        commit("USER_SUMMARY_FETCHED", resp.data);
    }).catch(() => {
        commit("USER_SUMMARY_FETCHED", []);
    });
};

const fetchUnreadCount = ({ commit, state }) => {
    createHttp(state.token).get(config.apiUrl + "/common/messages/calc_unread")
        .then((resp) => {
            var unreadCount = resp.data;
            unreadCount.Inbox = Object.values(unreadCount).reduce((acc, value) => acc + value, 0);
            commit("UNREAD_COUNT_FETCHED", unreadCount);
        });
};

const fetchZenTotalCount = ({ commit, state }, gameName) => {
    createHttp(state.token).get(config.apiUrl + "/common/messages/calc", {
        params: {
            folder: "Inbox",
            tags: state.zen.tags || [],
            unreadOnly: true,
            statuses: ["new", "renewal", "pending"],
            projects: [gameName]
        }
    }).then(function (result) {
        commit("ZEN_TOTAL_COUNT_FETCHED", result.data);
    });
};

const fetchTags = ({ commit, state }) => {
    createHttp(state.token).get(config.apiUrl + "/common/fetchtags").then(function (results) {
        var tags = {
            all: results.data.sort(function (a, b) {
                return a.tag.toLowerCase().localeCompare(b.tag.toLowerCase());
            }).map(function (tagData) {
                return tagData.tag;
            })
        };
        commit("TAGS_FETCHED", tags);
    });
};

const fetchInboxCount = ({ commit, state }, gameName) => {
    var folder = router.currentRoute.value.params.folder.split(":")[0];
    var payersOnly = false;
    if (folder.startsWith("$")) {
        payersOnly = true;
    }

    createHttp(state.token).get(config.apiUrl + "/common/messages/calc", {
        params: {
            folder: folder,
            payersOnly: payersOnly,
            enOnly: false,
            tags: state.filter.byTag,
            languages: state.filter.byLanguage,
            statuses: state.filter.byStatus,
            sources: state.filter.bySource,
            respondingPersons: state.filter.byRespondingPerson,
            unreadOnly: state.filter.unreadOnlyMode,
            withAnswers: state.filter.withAnswers,
            latestVersion: state.filter.latestVersion,
            from: state.filter.byDate.from,
            to: state.filter.byDate.to,
            episodes: state.filter.byEpisode,
            levels: state.filter.byLevel,
            projects: [gameName]
        }
    }).then(function (result) {
        commit(payersOnly ? "INBOX_COUNT_FETCHED_PAYERS" : "INBOX_COUNT_FETCHED", result.data);
    });
};

// eslint-disable-next-line no-unused-vars
const search = _.debounce(({ dispatch, state, commit }, data) => {
    commit("SEARCH_QUERY", data.query);
    var folder = router.currentRoute.value.params.folder.split(":")[0];
    if (data.query.length > 2 || folder.indexOf("Inbox") === -1) {
        dispatch("fetchDialogues");
    } else {
        commit("DIALOGUES_FETCHED", []);
    }
}, 300);

// eslint-disable-next-line no-unused-vars
const filterByTag = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_TAG", data);
};

// eslint-disable-next-line no-unused-vars
const filterByEpisode = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_EPISODE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByLevel = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_LEVEL", data);
};
// eslint-disable-next-line no-unused-vars
const filterByLanguage = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_LANGUAGE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByStatus = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_STATUS", data);
};
// eslint-disable-next-line no-unused-vars
const filterBySource = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_SOURCE", data);
};
// eslint-disable-next-line no-unused-vars
const filterByRespondingPerson = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_RESPONDING_PERSON", data);
};
// eslint-disable-next-line no-unused-vars
const filterByDate = ({ dispatch, commit }, data) => {
    commit("CHANGE_FILTER_DATE", data);
};

const changeWithAnswersMode = ({ commit }, data) => {
    commit("WITH_ANSWERS_MODE_CHANGED", data);
};

const changeLatestVersionMode = ({ commit }, data) => {
    commit("LATEST_VERSION_MODE_CHANGED", data);
};

const changeUnreadOnlyMode = ({ commit }, data) => {
    commit("UNREAD_ONLY_MODE_CHANGED", data);
};

const changeGroupGamesAndSources = ({ commit }, data) => {
    commit("GROUP_GAMES_AND_SOURCES_CHANGED", data);
};

const changePayersOnlyMode = ({ commit }, data) => {
    commit("PAYERS_ONLY_MODE_CHANGED", data);
};

const sendAnswer = ({ dispatch, commit, state }, data) => {
    commit("ADD_TEMPORARY_MESSAGE", data);

    data.projectId = getGames()[0];
    createHttp(state.token).post(config.apiUrl + "/common/message", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userId,
            dialogueId: state.dialogueId,
            stayOnCard: true
        });
        dispatch("fetchUnreadCount");
    });
};
// eslint-disable-next-line no-unused-vars
const removeMessage = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(config.apiUrl + "/common/removemessage", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
        dispatch("fetchUnreadCount");
    });
};
// eslint-disable-next-line no-unused-vars
const editMessage = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(config.apiUrl + "/common/editmessage", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
    });
};
// eslint-disable-next-line no-unused-vars
const glueDialogue = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).post(config.apiUrl + "/common/gluedialogue", data).then(() => {
        state.dialogueId = undefined;
        dispatch("fetchUserCard", { userId: data.userid });
    });
};

const mark = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game)).map(function (game) {
        return createHttp(state.token).put(config.apiUrl + "/common/mark", {
            ids: dialoguesMap.get(game),
            mark: 1
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
    });
};

const unmark = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game)).map(function (game) {
        return createHttp(state.token).put(config.apiUrl + "/common/mark", {
            ids: dialoguesMap.get(game),
            mark: 0
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
    });
};

const removeUser = ({ dispatch, state }, userId) => {
    createHttp(state.token).delete(config.userToolUrl.replace("{game}", getGames()[0]), {
        data: {
            id: userId
        }
    }).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const addToBlackList = ({ dispatch, state }, userId) => {
    createHttp(state.token).put(config.userToolUrl.replace("{game}", getGames()[0]) + "/addtoblacklist/", {
        id: userId
    }).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const androidRefund = ({ dispatch, state }, data) => {
    createHttp(state.token).put(config.userToolUrl.replace("{game}", getGames()[0]) + "/androidrefund/", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
    });
};

const setCheater = ({ dispatch, state }, userId) => {
    createHttp(state.token).put(config.userToolUrl.replace("{game}", getGames()[0]) + "/setcheater/", {
        id: userId
    }).then(() => {
        dispatch("fetchUserCard", {
            userId: userId,
            dialogueId: state.dialogueId
        });
    });
};

const setSpammer = ({ dispatch, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/setspammer/", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.id,
            dialogueId: state.dialogueId
        });
    });
};

const addTag = ({ dispatch, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/settag", data).then(() => {
        dispatch("fetchUserCard", {
            userId: data.userid,
            dialogueId: state.dialogueId
        });
        dispatch("fetchTags");
    });
};

const addTags = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game)).map(function (game) {
        return createHttp(state.token).put(config.apiUrl + "/common/settag", {
            ids: dialoguesMap.get(game),
            tag: data.tag,
            author: data.author
        });
    })).then(() => {
        dispatch("fetchDialogues");
        dispatch("fetchTags");
    });
};

const removeTag = ({ dispatch, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/removetag", data).then(() => {
        if (data.fromUserCard) {
            dispatch("fetchUserCard", {
                userId: data.userid,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchTags");
    });
};

const editTag = ({ dispatch, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/edittag", data).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchTags");
    });
};
// eslint-disable-next-line no-unused-vars
var login = function ({ dispatch, commit, state }, model) {
    createHttp(state.token).post(config.apiUrl + "/common/login", model).then(async (result) => {
        if (result.data.success) {
            commit("SET_TOKEN", result.data);
            await router.push("/");
        } else {
            alert(result.data);
        }
    });
};

const markAsRead = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game)).map(function (game) {
        return createHttp(state.token).put(config.apiUrl + "/common/markAsRead", {
            ids: dialoguesMap.get(game),
            read: 1
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchUnreadCount");
    });
};

const markAsUnread = ({ dispatch, state }, data) => {
    const dialoguesMap = utils.groupDialogueIdsByGames(data.dialogueIds);
    axios.all(getGames().filter((game) => dialoguesMap.has(game)).map(function (game) {
        return createHttp(state.token).put(config.apiUrl + "/common/markAsRead", {
            ids: dialoguesMap.get(game),
            read: 0
        });
    })).then(() => {
        if (data.userId) {
            dispatch("fetchUserCard", {
                userId: data.userId,
                dialogueId: state.dialogueId
            });
        } else {
            dispatch("fetchDialogues");
        }
        dispatch("fetchUnreadCount");
    });
};
// eslint-disable-next-line no-unused-vars
const translate = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/translate", {
        sourceLang: data.sourceLang,
        lang: data.lang,
        text: data.message
    }).then(function (result) {
        commit("MESSAGE_TRANSLATED", {
            id: data.id,
            data: result.data
        });
        if (data.callback) {
            data.callback();
        }
    });
};
// eslint-disable-next-line no-unused-vars
const setStatus = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/setstatus", data).then(() => {
        if (state.zen && state.zen.active && state.zen.userIds.indexOf(data.userid) !== -1) {
            dispatch("zenCardProcessed", data);
        } else {
            dispatch("fetchUserCard", { userId: data.userid });
        }
    });
};
// eslint-disable-next-line no-unused-vars
const activateZen = ({ dispatch, commit, state }, data) => {
    createHttp(state.token).put(config.apiUrl + "/common/zenmode/activate", data).then(async function (result) {
        var activationResult = result.data;
        if (!activationResult || !activationResult.userIds || activationResult.userIds.length === 0) {
            commit("SET_ZEN_STATE", {
                active: false,
                game: data.game,
                userIds: [],
                error: "No messages found for '" + data.game + "'" + (data.tags && data.tags.length > 0 ? " with tag '" + data.tags[0] + "'" : "") + ". Try to change parameters.",
                tags: data.tags
            });
        } else {
            commit("SET_ZEN_STATE", {
                active: true,
                game: data.game,
                initialUserIdsCount: activationResult.userIds.length,
                error: undefined,
                userIds: activationResult.userIds,
                tags: activationResult.zenTag ? [activationResult.zenTag] : []
            });
            await router.push({ name: "UserCard", params: { folder: "Inbox:" + data.game, userId: activationResult.userIds[0] } });
        }
    });
};

const zenCardProcessed = async ({ dispatch, commit, state }, data) => {
    state.zen.userIds.splice(state.zen.userIds.indexOf(data.userid), 1);

    if (state.zen.userIds.length > 0) {
        commit("SET_ZEN_STATE", {
            active: true,
            game: data.game,
            userIds: state.zen.userIds,
            initialUserIdsCount: state.zen.initialUserIdsCount,
            payersOnly: state.zen.payersOnly,
            tags: state.zen.tags 
        });
        await router.push({ name: "UserCard", params: { folder: "Inbox:" + data.game, userId: state.zen.userIds[0] } });

        dispatch("fetchZenTotalCount", data.game);
    } else {
        commit("SET_ZEN_STATE", {
            active: false, game: data.game, userIds: [], payersOnly: state.zen.payersOnly, tags: state.zen.tags 
        });
        await router.push("/");
    }
};

var getGames = function () {
    var games = router.currentRoute.value.params.folder ? router.currentRoute.value.params.folder.split(":")[1].split(",") : ["all"];
    return config.games.filter(function (game) {
        return games[0] === "all" || games.indexOf(game) !== -1;
    });
};

export default {
    fetchDialogues,
    fetchUserCard,
    fetchUserSummary,
    fetchUnreadCount,
    fetchInboxCount,
    fetchTags,
    fetchZenTotalCount,

    login,
    search,
    sendAnswer,
    removeMessage,
    editMessage,

    mark,
    unmark,
    removeUser,
    addToBlackList,
    androidRefund,
    setCheater,
    setSpammer,
    markAsRead,
    markAsUnread,
    glueDialogue,
    translate,

    activateZen,
    zenCardProcessed,

    addTag,
    addTags,
    editTag,
    setStatus,
    removeTag,

    filterByTag,
    filterByStatus,
    filterByLanguage,
    filterBySource,
    filterByDate,
    filterByRespondingPerson,
    filterByEpisode,
    filterByLevel,
    changeWithAnswersMode,
    changeLatestVersionMode,
    changeUnreadOnlyMode,
    changeGroupGamesAndSources,
    changePayersOnlyMode
};
