/**
* Created by Andrey Popov on 11/1/21.
*/

<template>
    <div class='string-input'>
        <div class="string-input-title">
            {{ title }}
        </div>
        <input
            class='string-input-input'
            debounce='1000'
            v-model="value"
        />
    </div>
</template>

<script>
export default {
    name: 'StringInput',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            default: ''
        },
        title: {
            type: String
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    }
};
</script>

<style scoped>
.string-input {
    margin: 4px 0;
}

.string-input *,
.string-input *::after,
.string-input *::before {
    box-sizing: border-box;
}

.string-input-input {
    border: 1px solid #ebebeb;
    font-size: 16px;
    height: 28px;
    margin: 0;
    outline: none;
    text-align: center;
    width: calc(100% - 56px);
}

.string-input-input::-webkit-outer-spin-button, .string-input-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.string-input-title {
    transition: background 0.5s ease;
    font-size: 16px;
    text-align: center;
}

</style>