/**
 * Created by mac on 12/11/20
 */

var Families = {};

var FamiliesIds = {
    wood: 1,
    stone: 2,
    wagon: 3,
    bamboo: 4,
    sapphire: 5,
    snow: 6,

    wheat: 7,
    candies: 8,
    apple: 9,
    strawberry: 10,
    mushrooms: 11,
    corn: 12,

    treasure: 13,
    herochest: 14,
    freechest: 15,
    dailytaskchest: 16,
    sacks: 17,

    coinsplantchest: 18,
    rubiesplantchest: 19,
    energyplantchest: 20,

    woodchest: 21,
    stonechest: 22,
    wagonchest: 23,
    bamboochest: 24,
    sapphirechest: 25,
    snowchest: 26,

    wands: 27,
    coins: 28,
    rubies: 29,

    worker: 30,
    crystal: 31,

    woodsource: 32,
    stonesource: 33,
    wagonsource: 34,
    bamboosource: 35,
    sapphiresource: 36,
    snowsource: 37,

    woodgenerator: 38,
    stonegenerator: 39,
    wagongenerator: 40,
    bamboogenerator: 41,
    sapphiregenerator: 42,
    snowgenerator: 43,

    dishes: 44, // backward compatibility

    dwarf: 45,
    king: 46,
    bear: 47,
    elf: 48,
    warlock: 49,
    troll: 50,
    knight: 51,
    hunter: 52,

    kraken: 53,

    multiCellBody: 54,
    thirdelement: 55,
    dragonmine: 56,
    // decorator: 57,

    magicplant: 58,

    rubiesplant: 59,
    coinsplant: 60,
    energyplant: 61,

    fruitshop: 62,
    resourceshop: 63,
    discountshop: 64,

    telly: 65,

    bppointscrystal: 66,
    bppointsstar: 67,

    barrel: 68,

    // seasonalspot: 69,
    seasonchest: 70,
    // hlseasonalitem: 71,

    // hlshop: 72,
    // hlshopchest: 73,
    // hlfreechest: 74,
    //
    // hlfranky: 75,
    // hlgrave: 76,
    // hlgravesource: 77,
    // hlgravechest: 78,
    //
    // hlwitch: 79,
    // hlcaldron: 80,
    // hlcaldronsource: 81,
    // hlcaldronchest: 82,
    //
    // hlskeleton: 83,
    // hlghost: 84,
    // hlghostsource: 85,
    // hlghostchest: 86,

    // hlgrowing: 87,
    // hlscarecrow: 88,
    // hldecorator: 89,
    // hlbattlepass: 90,
    // hlgrowingseeds: 91,
    // hlmagicplant: 92,
    // hlhouse: 93,
    // hlportal: 94,
    ruins: 95,

    // xmcookie: 96, // deleted
    // xmsanta: 97, // deleted
    // xmsnowman: 98, // deleted
    // xmdeer: 99, // deleted
    // xmyeti: 100, // deleted
    // xmelf: 101, // deleted

    // xmtoy: 102, // deleted
    // xmsocks: 103, // deleted
    // xmcandy: 104, // deleted
    // xmsled: 105, // deleted
    // xmcastle: 106, // deleted

    // xmtoysource: 107, // deleted
    // xmsockssource: 108, // deleted
    // xmcandysource: 109, // deleted
    // xmsledsource: 110, // deleted
    // xmcastlesource: 111, // deleted

    // xmtoychest: 112, // deleted
    // xmsockschest: 113, // deleted
    // xmcandychest: 114, // deleted
    // xmsledchest: 115, // deleted
    // xmcastlechest: 116, // deleted

    // xmshopchest: 117, // deleted
    // xmfreechest: 118, // deleted

    // xmshop: 119, // deleted
    // xmmagicplant: 120, // deleted
    // xmbattlepass: 121, // deleted
    // xmgrowing: 122, // deleted
    // xmhouse: 123, // deleted

    season0: 124,
    season1: 125,
    season2: 126,
    season3: 127,
    season4: 128,
    season5: 129,
    season6: 130,
    season7: 131,
    season8: 132,
    season9: 133,
    season10: 134,
    season11: 135,

    // xmfactory: 136, // deleted
    // xmrudolf: 137, // deleted
    // xmgiftball: 138, // deleted
    // xmgiftdoll: 139, // deleted
    // xmgiftbear: 140, // deleted

    lava: 141,
    lavachest: 142,
    lavasource: 143,
    lavagenerator: 144,

    // xmhunter: 146, // deleted
    centaur: 147,
    unknown: 148,

    // dragonia
    dragonblue: 149,
    dr2dragonblue: 418,
    dragongreen: 150,
    dr2dragongreen: 419,
    dragonyellow: 151,
    dr2dragonyellow: 420,
    dragonred: 152,
    dr2dragonred: 421,
    dr2dragonpurple: 422, // 2
    dr2dragonrainbow: 423, // 2
    dragonruby: 242,
    dr2dragonruby: 425, // 2
    dryeti: 153, // /1
    drelf: 154, // 1
    dragonpack: 283,
    dr2dragonpack: 424, // 2
    dr2dragonbutterfly: 478, // 2

    drwood: 155,
    drstone: 156,
    drsapphire: 157,
    drsled: 158, //
    drcastle: 159, //
    driron: 429, // backward compatibility 06.12.2022
    dryellowcrystal: 430, // backward compatibility 06.12.2022
    drpurplecrystal: 462, // 2
    drfeathers: 466, // 2
    drbamboo: 470, // 2

    drwoodsource: 160,
    drstonesource: 161,
    drsapphiresource: 162,
    drsledsource: 163, // 1
    drcastlesource: 164, // 1
    drironsource: 434, // backward compatibility 06.12.2022
    dryellowcrystalsource: 435, // backward compatibility 06.12.2022
    drpurplecrystalsource: 463, // 2
    drfeatherssource: 467, // 2
    drbamboosource: 471, // 2

    drwoodchest: 165,
    drstonechest: 166,
    drsapphirechest: 167,
    drsledchest: 168, // 1
    drcastlechest: 169, // 1
    drfeatherschest: 468, // 2
    drbamboochest: 472, // 2

    drshopchest: 170,
    drfreechest: 171,
    drironchest: 439, // backward compatibility 06.12.2022
    dryellowcrystalchest: 440, // backward compatibility 06.12.2022
    drpurplecrystalchest: 464, // 2
    drsack: 461, // 2

    drshop: 172,
    drmagicplant: 173,
    drbattlepass: 174,
    drgrowing: 175,
    drhouse: 176,

    drfactory: 177, // 1
    drrudolf: 178, // 1
    drgiftaxe: 179, // 1
    drgiftcuirass: 180, // 1
    drgifthelm: 181, // 1
    drgiftgenerator: 182, // 1
    drgiftshield: 184, // 1

    drstonegenerator: 185,
    drsapphiregenerator: 186,
    drsledgenerator: 187, // 1
    drcastlegenerator: 188, // 1
    drirongenerator: 443, // backward compatibility 06.12.2022
    dryellowcrystalgenerator: 444, // backward compatibility 06.12.2022
    drpurplecrystalgenerator: 465, // 2
    drfeathersgenerator: 469, // 2
    drbamboogenerator: 473, // 2
    drwoodgenerator: 477, // 2

    drfruit: 460, // 2
    drtournament: 474, // 2
    drsnail: 475, // 2
    drsnailhouse: 476, // 2
    drportal: 241,

    dr2wood: 426, // backward compatibility 06.12.2022
    dr2stone: 427, // backward compatibility 06.12.2022
    dr2sapphire: 428, // backward compatibility 06.12.2022
    dr2woodsource: 431, // backward compatibility 06.12.2022
    dr2stonesource: 432, // backward compatibility 06.12.2022
    dr2sapphiresource: 433, // backward compatibility 06.12.2022
    dr2woodchest: 436, // backward compatibility 06.12.2022
    dr2stonechest: 437, // backward compatibility 06.12.2022
    dr2sapphirechest: 438, // backward compatibility 06.12.2022
    dr2stonegenerator: 441, // backward compatibility 06.12.2022
    dr2sapphiregenerator: 442, // backward compatibility 06.12.2022
    dr2shopchest: 445, // backward compatibility 06.12.2022
    dr2freechest: 446, // backward compatibility 06.12.2022
    dr2shop: 447, // backward compatibility 06.12.2022
    dr2magicplant: 448, // backward compatibility 06.12.2022
    dr2battlepass: 449, // backward compatibility 06.12.2022
    dr2growing: 450, // backward compatibility 06.12.2022
    dr2house: 451, // backward compatibility 06.12.2022
    dr2portal: 459, // backward compatibility 06.12.2022

    // undersea
    sealisa: 243,
    sealumberjack: 244,
    sealittlegirl: 245,
    seagranny: 246,
    seafisherman: 247,
    seascout: 248,

    seacrab: 249,
    seafishes: 250,
    seashark: 251,
    seaoctopus: 252,
    seahorse: 253,

    seacrabsource: 254,
    seafishessource: 255,
    seasharksource: 256,
    seaoctopussource: 257,
    seahorsesource: 258,

    seacrabchest: 259,
    seafisheschest: 260,
    seasharkchest: 261,
    seaoctopuschest: 262,
    seahorsechest: 263,

    seashopchest: 264,
    seafreechest: 265,

    seashop: 266,
    seamagicplant: 267,
    seabattlepass: 268,
    seagrowing: 269,
    seahouse: 270,

    seafactory: 271,
    searudolf: 272,
    seaanchor: 273,
    seawheel: 274,
    seaspyglass: 275,
    seapiratehook: 276,
    seagiftgenerator: 277,

    seafishesgenerator: 278,
    seasharkgenerator: 279,
    seaoctopusgenerator: 280,
    seahorsegenerator: 281,
    seaportal: 282,

    walrusruby: 284,

    coinscup: 285,
    energycup: 286,

    energy: 287,
    underseapack: 288,
    caravanbox: 289,

    gnomes: 297,

    caravanship: 298,

    sand: 299,
    sandchest: 300,
    sandsource: 301,
    sandgenerator: 302,

    asia: 303,
    asiachest: 304,
    asiasource: 305,
    asiagenerator: 306,

    bank: 307,

    cinema: 308,

    sea2lisa: 319,
    sea2crab: 320, // backward compatibility
    sea2crabsource: 321, // backward compatibility
    sea2crabchest: 322, // backward compatibility
    seacrabgenerator: 323,

    sea2shopchest: 324, // backward compatibility
    sea2freechest: 325, // backward compatibility
    sea2shop: 326, // backward compatibility
    sea2magicplant: 327, // backward compatibility
    sea2battlepass: 328, // backward compatibility
    sea2portal: 329, // backward compatibility

    sea2fishes: 330, // backward compatibility
    sea2shark: 331, // backward compatibility
    sea2octopus: 332, // backward compatibility

    sea2jellyfish: 333, // backward compatibility
    sea2jellyfishsource: 334, // backward compatibility
    sea2jellyfishchest: 335, // backward compatibility
    sea2jellyfishgenerator: 336, // backward compatibility

    sealochness: 337,
    sealochnesssource: 338,
    sealochnesschest: 339,
    sealochnessgenerator: 340,

    seaturtle: 349,
    seaturtlesource: 350,
    seaturtlechest: 351,
    seaturtlegenerator: 352,

    seashrimps: 353,
    seashrimpssource: 354,
    seashrimpschest: 355,
    seashrimpsgenerator: 356,

    sea2fishessource: 357, // backward compatibility
    sea2sharksource: 358, // backward compatibility
    sea2octopussource: 359, // backward compatibility
    sea2fishesgenerator: 360, // backward compatibility
    sea2sharkgenerator: 361, // backward compatibility
    sea2octopusgenerator: 362, // backward compatibility
    sea2fisheschest: 363, // backward compatibility
    sea2sharkchest: 364, // backward compatibility
    sea2octopuschest: 365, // backward compatibility
    sea2lumberjack: 366,
    sea2littlegirl: 367,
    sea2granny: 368,
    sea2fisherman: 369,
    sea2scout: 370,
    sea2walrusruby: 371,
    sea2pack: 372,
    seastrawberry: 373,
    seasnail: 374,
    seasack: 375,
    sea2house: 376, // backward compatibility
    undersea2pack: 377, // backward compatibility
    sea2capitannemo: 378,
    seatournament: 388,
    // hlbat: 389,
    // hlbatsource: 390,
    // hlbatchest: 391,
    // hlgravegenerator: 392,
    // hlcaldrongenerator: 393,
    // hlghostgenerator: 394,
    // hlbatgenerator: 395,
    landmark: 396,
    // hlpack: 397,
    shark: 398,
    landmarkspot: 399,
    landmarkpreview: 291,

    rpmushroom: 400,
    rpbat: 401,

    seasnailhouse: 402,
    energytree: 403,
    coinstree: 404,

    rprapunzel: 405,

    rpanimals: 406,
    rpcomb: 407,
    rpcandle: 408,
    rpperfume: 409,

    rpdress: 410,
    rpjewelry: 411,
    rpchameleon: 412,
    rpdoll: 413,

    rpdollsource: 414,
    rpchameleonsource: 415,
    rpdresssource: 416,
    rpjewelrysource: 417,

    rpprince: 479,
    rpthrone: 480,
    rpshopchest: 481,
    rpfreechest: 482,
    rpshop: 483,

    // xmgnome: 484, // deleted
    // xmplane: 485, // deleted
    // xmorder: 486, // deleted
    // xmlamberjack: 487, // deleted

    // xmtoygenerator: 488, // deleted
    // xmsocksgenerator: 489, // deleted
    // xmcandygenerator: 490, // deleted
    // xmsledgenerator: 491, // deleted
    // xmcastlegenerator: 492, // deleted

    // xmpack: 493, // deleted

    // xmbread: 494, // deleted
    // xmigloo: 495, // deleted

    // xmbreadsource: 496, // deleted
    // xmigloosource: 497, // deleted

    // xmbreadchest: 498, // deleted
    // xmigloochest: 499, // deleted

    // xmbreadgenerator: 500, // deleted
    // xmigloogenerator: 501, // deleted

    rpspider: 503,
    rpspidersource: 504,
    rpmushroomsource: 505,
    rpbatsource: 506,

    // xmbridge: 507, // deleted
    rpcentaur: 508,
    rptelescope: 509,
    rppress: 510,
    rptrolley: 511,
    rpcrane: 512,
    rpdoorway: 513,
    rpcampaign: 514,
    rpprincehero: 515,
    rprapuncust: 516,

    dr2field: 517,
    dr2wheat: 518,
    dr2blacksmith: 519,
    dr2treasuremap: 520,
    dr2airship: 521,
    dr2mine: 522,
    dr2coal: 523,

    warcraft: 524,
    warcraftchest: 525,
    warcraftsource: 526,
    warcraftgenerator: 527,

    dr2campaign: 528,

    eaeggssource: 529,
    earabbitsource: 530,
    eachicksource: 531,
    eabreadsource: 532,
    eaeggs: 533,
    earabbit: 534,
    eachick: 535,
    eabread: 536,
    eabaskets: 537,
    eateaset: 538,
    eatulips: 539,
    eabell: 540,
    eaprince: 541,
    eathrone: 542,
    eaunicorn: 543,
    eashopchest: 544,
    eafreechest: 545,
    eashop: 546,

    dr2sawmill: 547,
    dr2barrel: 548,
    dr2elf: 549,

    rpsourcegenerator: 550,
    easourcegenerator: 551,

    // collection_units
    clsack: 578,
    clstrawberry: 579,
    clshop: 580,
    clpawchest: 582,
    clsimplechest: 584,
    clpaychest: 585,
    clnarrator: 586,
    cllandmark: 602,

    cllongneck: 292,
    clmantis: 293,
    clray: 294,
    clsnail: 295,
    clbat: 296,
    clbuckbeak: 310,
    clfrog: 311,
    cltechnosphinx: 312,
    clmonkeyking: 313,
    clturtle: 314,
    clbeedragon: 552,
    cldeerdragon: 553,
    clfishdragon: 554,
    clgorgon: 555,
    clgriffin: 556,
    clhydra: 557,
    cljapanleon: 558,
    clkitsune: 559,
    clphoenix: 560,
    clsalamandra: 561,
    clsnowdragon: 562,
    clcrystaldragon: 563,
    clcyclops: 564,
    cldemon: 565,
    clkoidragon: 566,
    clmanticore: 567,
    clorc: 568,
    clowldragon: 569,
    clvasilisk: 570,
    clcerberus: 571,
    clchameleon: 572,
    clchinesedragon: 573,
    clent: 574,
    clmothcat: 575,
    clmushroomdragon: 576,
    clpegasus: 577,
    clminotaur: 583,
    cltirex: 591,
    clsnowbeast: 597,
    clwyvern: 660,
    clbunnydragon: 668,
    clzebra: 669,
    cllavadragon: 670,
    clsheepy: 716,
    clchubby: 717,
    clplateddragon: 723,
    clanglerfishdragon: 724,
    clplantdragon: 725,
    clfiredragon: 726,
    clfruitdragon: 727,

    fish: 581,

    steampunk: 587,
    steampunkchest: 588,
    steampunksource: 589,
    steampunkgenerator: 590,

    panda: 592,

    moonstone: 593,
    moonstonechest: 594,
    moonstonesource: 595,
    moonstonegenerator: 596,

    drproducer1a: 598,
    drcustomermain3: 601,

    blockerator: 603, // backward compatibility 18.09.2023
    priestess: 604,

    // hlpumpkin: 604,
    // hlpumpkinsource: 605,
    // hlpumpkinchest: 606,
    // hlpumpkingenerator: 607,
    // hlcastle: 608,
    // hlhag: 609,
    // hlmummie: 610,
    // hlcampaign: 611,
    // hlgnome: 612,
    // hlapplegenerator: 613,
    // hlberrygenerator: 614,
    // hlricegenerator: 615,
    // hlflittermouse: 616,
    // hlspider: 617,
    // hlcandle: 618,
    // hlhellcat: 192,

    debugunit: 190,

    drcustomerbridge1: 619,
    drproduct0b: 620,
    drproduct1b: 621,
    drproducertrash: 622,
    drproducer0a: 623,
    drproduct2a: 624,
    drproducer2a: 625,
    drproducer1b: 626,
    drproducer2b: 627,
    drcustomermain0: 628,
    drproduct1a: 629,
    drproduct0a: 630,

    thanksgiving: 631,

    drcustomermain2: 632,
    drcustomermain1: 633,
    drbattlepass1: 634,
    drbattlepass2: 635,
    drbattlepass3: 636,
    drbattlepass4: 637,
    drbattlepass5: 638,

    // xmproducertrash: 193, // deleted
    // xmcustomerbridge1: 194, // deleted
    // xmcustomerbridge2: 195, // deleted
    // xmcustomerbridge3: 196, // deleted
    // xmproduct0a: 197, // deleted
    // xmproduct0b: 198, // deleted
    // xmproduct1b: 199, // deleted
    // xmproduct1a: 200, // deleted
    // xmproduct2a: 201, // deleted
    // xmproducer0a: 202, // deleted
    // xmproducer1a: 203, // deleted
    // xmproducer1b: 204, // deleted
    // xmproducer2a: 205, // deleted
    // xmproducer2b: 206, // deleted
    // xmcustomermain0: 207, // deleted
    // xmcustomermain1: 208, // deleted
    // xmcustomermain2: 209, // deleted
    // xmcustomermain3: 210, // deleted
    // xmcustomermain4: 211, // deleted
    // xmcustomermain5: 212, // deleted

    // chbamboo: 213, // deleted
    // chbamboosource: 214, // deleted
    // chcastle: 215, // deleted
    // chcastlesource: 216, deleted
    // chdragongreen: 217, // deleted
    // chdragonpurple: 218, // deleted
    // chdragonrainbow: 219, // deleted
    // chdragonred: 220, // deleted
    // chdragonyellow: 221, // deleted
    // chfeathers: 222, // deleted
    // chfeatherssource: 223, // deleted
    // chfreechest: 224, // deleted
    // chnarrator: 225, // deleted
    // chsapphire: 226, // deleted
    // chsapphiresource: 227, // deleted
    // chshopchest: 228, // deleted
    // chsourcegenerator: 229, // deleted
    // chthrone: 230, // deleted
    // chshop: 231, // deleted

    rpplatesource: 232,
    rppanssource: 233,
    rpmugssource: 234,
    rpcutlerysource: 235,
    rpplate: 236,
    rppans: 237,
    rpmugs: 238,
    rpcutlery: 239,

    rpproduct0: 315,
    rpproduct1: 316,
    rpproduct2: 317,
    rpproduct3: 318,
    rpproduct4: 675,
    rpproducer0: 639,
    rpproducer1: 640,
    rpproducer2: 641,
    rpproducer3: 642,
    rpproducer4: 698,
    rpcustomerbridge0: 644,
    rpcustomerbridge1: 645,
    rpcustomerbridge2: 646,
    rpcustomerbridge3: 647,
    rpcustomerbridge4: 696,
    rpcustomermain0a: 648,
    rpcustomermain0b: 649,
    rpcustomermain1a: 650,
    rpcustomermain1b: 651,
    rpcustomermain1c: 652,
    rpcustomermain2a: 653,
    rpcustomermain2b: 654,
    rpcustomermain2d: 659,
    rpcustomermain2c: 655,
    rpcustomermain3a: 656,
    rpcustomermain3b: 657,
    rpcustomermain3c: 658,
    rpcustomermain3d: 667,
    rpcustomermain4a: 697,

    eacustomerbridge0a: 661,
    eacustomerbridge0b: 662,
    eacustomermain0a: 663,
    eacustomermain0b: 664,
    eaproduct0: 665,
    eaproducer0: 666,

    advgift: 671,
    advflower: 672,
    advpalmtree: 673,
    advchest: 674,
    // adv2customerbridge1: 676, // unused
    // adv2producer0: 677, // unused
    // adv2customermain0: 678, // unused
    // adv2customermain1: 679, // unused
    // adv2customermain2: 680, // unused
    advpile: 681,
    advrush: 682,
    // adv2customermain3: 683, // unused
    advcustomermain1: 684,
    advfireplace: 685,
    advshrub: 686,
    advrock: 687,
    advdynamite: 688,
    advlawn: 689,
    // adv2customermain5: 690, // unused
    // adv2customermain6: 691, // unused
    advbush: 692,
    // adv2customermain7: 693, // unused
    // adv2customerbridge0: 694, // unused
    // adv2customermain4: 695, // unused
    // advbush7: 699, // unused
    advtree: 700,
    // advtree1: 701, // unused
    // advtree2: 702, // unused
    // advtree3: 703, // unused
    // advtree4: 704, // unused
    advcustomerbridge1: 705,
    advcustomerbridge2: 706,
    advcustomermain2: 707,
    // advdoubloon: 708, // unused
    // advclover: 709, // unused
    // advgrass: 710, // unused
    // advlog: 711, // unused
    // advseashell: 712, // unused
    // advstick: 713, // unused
    // advstone: 714, // unused
    advcustomerbridge0: 715,

    seabattlepass1: 718,
    seabattlepass2: 719,
    seabattlepass3: 720,
    seabattlepass4: 721,
    seabattlepass5: 722,

    kingfood: 728,
    dr2dragoncup: 729,
    dwarffood: 730,

    rpcustomermain0: 731,
    rpcustomermain1: 732,
    rpcustomermain2: 733,
    rpcustomermain3: 734,
    rpcustomermain4: 735,

    // hearts
    cabbage: 741,
    carrots: 742,
    bread: 743,
    beer: 744,
    meat: 745,
    gear: 756,
    tools: 757,
    metal: 758,
    gardengenerator1: 759,
    gardengenerator2: 760,
    gardengenerator3: 761,
    taverngenerator1: 762,
    taverngenerator2: 763,
    taverngenerator3: 764,
    factorygenerator1: 765,
    factorygenerator2: 766,
    factorygenerator3: 767,
    gardencastle1: 768,
    gardencastle2: 769,
    taverncastle1: 770,
    taverncastle2: 771,
    factorycastle1: 772,
    factorycastle2: 773
};

Families.initialize = function () {
    Families.initializeBase();
    Families.afterInitialize();
};

Families.initializeBase = function () {
    Families.wood = FamiliesHelper.ResourceFamily(8, "wood", { basicPrice: 7 });
    Families.stone = FamiliesHelper.ResourceFamily(9, "stone", { basicPrice: 8 });
    Families.wagon = FamiliesHelper.ResourceFamily(10, "wagon", { basicPrice: 9 });
    Families.bamboo = FamiliesHelper.ResourceFamily(10, "bamboo", { lazy: true });
    Families.sapphire = FamiliesHelper.ResourceFamily(11, "sapphire", { lazy: true });
    Families.snow = FamiliesHelper.ResourceFamily(11, "snow", { lazy: true });
    Families.lava = FamiliesHelper.ResourceFamily(11, "lava", { lazy: true });
    Families.sand = FamiliesHelper.ResourceFamily(11, "sand", { lazy: true });
    Families.asia = FamiliesHelper.ResourceFamily(11, "asia", { lazy: true });
    Families.warcraft = FamiliesHelper.ResourceFamily(12, "warcraft", { lazy: true });
    Families.steampunk = FamiliesHelper.ResourceFamily(12, "steampunk", { lazy: true });
    Families.moonstone = FamiliesHelper.ResourceFamily(13, "moonstone", { lazy: true });

    Families.wheat = FamiliesHelper.FruitFamily({ code: "wheat", duration: "3 hour" });
    Families.candies = FamiliesHelper.FruitFamily({ code: "candies", duration: "4 hour" });
    Families.apple = FamiliesHelper.FruitFamily({ code: "apple", duration: "5 hour" });
    Families.strawberry = FamiliesHelper.FruitFamily({ code: "strawberry", duration: "3 hour" });
    Families.mushrooms = FamiliesHelper.FruitFamily({ code: "mushrooms", duration: "4 hour" });
    Families.corn = FamiliesHelper.FruitFamily({ code: "corn", duration: "5 hour" });

    Families.treasure = FamiliesHelper.ChestFamily("treasure", {
        price: [7, 18, 40],
        extraBundles: "all"
    });
    Families.herochest = FamiliesHelper.ChestFamily("herochest", {
        price: [14, 35, 95],
        extraBundles: ["halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "xmas", "rapunzel", "rapunzel2", "rapunzel3", "easter", "china"]
    });
    Families.freechest = FamiliesHelper.ChestFamily("freechest");
    Families.dailytaskchest = FamiliesHelper.ChestFamily("dailytaskchest", { extraBundles: "all" });
    Families.sacks = FamiliesHelper.ChestFamily("sacks", {
        price: [8, 20, 39],
        extraBundles: ["halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "xmas", "rapunzel", "rapunzel2", "rapunzel3", "easter", "china"],
        animation: false
    });

    Families.coinsplantchest = FamiliesHelper.ChestFamily("coinsplantchest");
    Families.rubiesplantchest = FamiliesHelper.ChestFamily("rubiesplantchest");
    Families.energyplantchest = FamiliesHelper.ChestFamily("energyplantchest");

    Families.woodchest = FamiliesHelper.ChestFamily("woodchest", { resourceChest: true });
    Families.stonechest = FamiliesHelper.ChestFamily("stonechest", { resourceChest: true });
    Families.wagonchest = FamiliesHelper.ChestFamily("wagonchest", { resourceChest: true });
    Families.bamboochest = FamiliesHelper.ChestFamily("bamboochest", { resourceChest: true });
    Families.sapphirechest = FamiliesHelper.ChestFamily("sapphirechest", { resourceChest: true });
    Families.snowchest = FamiliesHelper.ChestFamily("snowchest", { resourceChest: true });
    Families.lavachest = FamiliesHelper.ChestFamily("lavachest", { resourceChest: true });
    Families.sandchest = FamiliesHelper.ChestFamily("sandchest", { resourceChest: true });
    Families.asiachest = FamiliesHelper.ChestFamily("asiachest", { resourceChest: true });
    Families.warcraftchest = FamiliesHelper.ChestFamily("warcraftchest", { resourceChest: true });
    Families.steampunkchest = FamiliesHelper.ChestFamily("steampunkchest", { resourceChest: true });
    Families.moonstonechest = FamiliesHelper.ChestFamily("moonstonechest", { resourceChest: true });

    Families.wands = FamiliesHelper.ValuableFamily("wands", {
        amounts: [5, 15, 45, 135, 405, 1215, 3650, 10950, 32850, 98550],
        exp: [0, 1, 3, 5, 7, 10, 15, 20, 25, 31],
        collectible: true,
        expeditions: ["xmas", "dragonia2", "dragonia3", "undersea2", "undersea3", "main", "rapunzel"],
        extraBundles: ["halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "rapunzel2", "rapunzel3", "easter", "china"]
    });
    Families.coins = FamiliesHelper.ValuableFamily("coins", {
        amounts: [0, 1, 3, 10, 30, 90, 270, 810, 2430, 7300, 21900, 65700, 197100],
        exp: [0, 1, 2, 3, 5, 7, 9, 15, 20, 30, 40, 50, 60],
        collectible: true,
        shineFrom: 4,
        expeditions: "all"
    });
    Families.rubies = FamiliesHelper.ValuableFamily("rubies", {
        amounts: [0, 2, 6, 20, 60, 180, 540, 1620, 4860, 14580],
        exp: [0, 1, 1, 2, 3, 10, 15, 20, 25, 31],
        collectible: true,
        shineFrom: 2,
        expeditions: "all"
    });

    Families.worker = FamiliesHelper.CustomFamily(5, "custom", {
        price: [1, 3, 10, 33, 75],
        instantWorker: true,
        draggable: true,
        expeditions: ["xmas", "halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "rapunzel", "rapunzel3", "rapunzel2", "main", "easter", "china"],
        extraBundles: ["adventure", "adventure2"]
    });
    Families.crystal = FamiliesHelper.CustomFamily(4, "custom", {
        price: [3, 20, 131, 195],
        shine: true,
        draggable: true,
        info: {
            guide: GuideWindow,
            guideOptions: typeof CrystalGuideOptions !== "undefined" ? CrystalGuideOptions : undefined
        },
        expeditions: ["xmas", "halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "rapunzel", "rapunzel2", "rapunzel3", "main", "easter", "china", "collections"],
        extraBundles: ["adventure", "adventure2"]
    });

    Families.woodsource = FamiliesHelper.SourceFamily("wood", 0);
    Families.stonesource = FamiliesHelper.SourceFamily("stone", 0);
    Families.wagonsource = FamiliesHelper.SourceFamily("wagon", 1);
    Families.bamboosource = FamiliesHelper.SourceFamily("bamboo", 1);
    Families.sapphiresource = FamiliesHelper.SourceFamily("sapphire", 2);
    Families.snowsource = FamiliesHelper.SourceFamily("snow", 2);
    Families.lavasource = FamiliesHelper.SourceFamily("lava", 3);
    Families.sandsource = FamiliesHelper.SourceFamily("sand", 3);
    Families.asiasource = FamiliesHelper.SourceFamily("asia", 3);
    Families.warcraftsource = FamiliesHelper.SourceFamily("warcraft", 3);
    Families.steampunksource = FamiliesHelper.SourceFamily("steampunk", 3);
    Families.moonstonesource = FamiliesHelper.SourceFamily("moonstone", 3);

    Families.woodgenerator = FamiliesHelper.GeneratorFamily("wood");
    Families.stonegenerator = FamiliesHelper.GeneratorFamily("stone");
    Families.wagongenerator = FamiliesHelper.GeneratorFamily("wagon");
    Families.bamboogenerator = FamiliesHelper.GeneratorFamily("bamboo");
    Families.sapphiregenerator = FamiliesHelper.GeneratorFamily("sapphire");
    Families.snowgenerator = FamiliesHelper.GeneratorFamily("snow");
    Families.lavagenerator = FamiliesHelper.GeneratorFamily("lava");
    Families.sandgenerator = FamiliesHelper.GeneratorFamily("sand");
    Families.asiagenerator = FamiliesHelper.GeneratorFamily("asia");
    Families.warcraftgenerator = FamiliesHelper.GeneratorFamily("warcraft");
    Families.steampunkgenerator = FamiliesHelper.GeneratorFamily("steampunk");
    Families.moonstonegenerator = FamiliesHelper.GeneratorFamily("moonstone");

    Families.dwarf = FamiliesHelper.HeroFamily(2);

    Families.king = FamiliesHelper.HeroFamily(4);

    Families.bear = FamiliesHelper.HeroFamily(6);
    Families.elf = FamiliesHelper.HeroFamily(7);
    Families.warlock = FamiliesHelper.HeroFamily(8);
    Families.troll = FamiliesHelper.HeroFamily(9);
    Families.knight = FamiliesHelper.HeroFamily(10);
    Families.hunter = FamiliesHelper.HeroFamily(10);
    Families.centaur = FamiliesHelper.HeroFamily(10);
    Families.gnomes = FamiliesHelper.HeroFamily(11);
    Families.shark = FamiliesHelper.HeroFamily(11);
    Families.fish = FamiliesHelper.HeroFamily(11);
    Families.panda = FamiliesHelper.HeroFamily(11);

    if (cleverapps.config.debugMode) {
        Families.priestess = FamiliesHelper.HeroFamily(11);
    }

    Families.kraken = FamiliesHelper.MonsterFamily("kraken", {
        mission: Mission.TYPE_KRAKENFEAST
    });

    Families.thanksgiving = FamiliesHelper.ThanksGivingFamily({ total: 10 });

    Families.multiCellBody = FamiliesHelper.SpecialUnitFamily({}, {
        expeditions: "all"
    });

    Families.thirdelement = FamiliesHelper.ThirdElementUnitFamily({ expeditions: "all" });

    Families.magicplant = FamiliesHelper.MagicPlantFamily({
        total: 9,
        type: "magicplant",
        code: "magicplant",
        timeout: "2 hours",
        prize: [{
            units: [
                { code: "crystal", stage: 1 }
            ]
        }, {
            probabilities: [0.5],
            units: [
                { code: "crystal", stage: 1 }
            ]
        }
        ],
        extraBundles: ["halloween", "blackfriday", "xmas", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "china", "easter", "rapunzel3"]
    });

    Families.rubiesplant = FamiliesHelper.RubiesPlantFamily(3);
    Families.coinsplant = FamiliesHelper.CoinsPlantFamily(3);
    Families.energyplant = FamiliesHelper.EnergyPlantFamily(3);

    Families.energytree = FamiliesHelper.ValuableTreeFamily({
        type: "energytree",
        code: "energytree",
        price: [14, 35, 95, 240],
        extraBundles: ["halloween", "blackfriday", "xmas", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "china", "easter", "rapunzel3"],
        stages: [{
        }, {
            prize: [{
                units: [{ code: "energy", stage: 1 }]
            }, {
                units: [{ code: "energy", stage: 1 }]
            }],
            timeout: "8 hours",
            unmergeable: true,
            breakable: {
                limit: 6
            }
        }, {
            prize: [{
                units: [{ code: "energy", stage: 1 }]
            }, {
                units: [{ code: "energy", stage: 1 }]
            }, {
                units: [{ code: "energy", stage: 0 }]
            }, {
                probabilities: [0.5],
                units: [{ code: "energy", stage: 0 }]
            }, {
                probabilities: [0.5],
                units: [{ code: "energy", stage: 0 }]
            }],
            timeout: "6 hours",
            unmergeable: true,
            breakable: {
                limit: 12
            }
        }, {
            prize: [{
                units: [{ code: "energy", stage: 2 }]
            }, {
                units: [{ code: "energy", stage: 0 }]
            }, {
                probabilities: [0.5],
                units: [{ code: "energy", stage: 0 }]
            }],
            timeout: "4 hours",
            unmergeable: true,
            breakable: {
                limit: 24
            }
        }]
    });

    Families.coinstree = FamiliesHelper.ValuableTreeFamily({
        type: "coinstree",
        code: "coinstree",
        price: [14, 35, 95, 240, 5],
        extraBundles: ["halloween", "blackfriday", "xmas", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "china", "easter", "rapunzel3"],
        stages: [{
        }, {
            prize: [{
                units: [{ code: "coins", stage: 4 }]
            }, {
                units: [{ code: "coins", stage: 3 }]
            }],
            timeout: "8 hours",
            unmergeable: true,
            breakable: {
                limit: 6
            }
        }, {
            prize: [{
                units: [{ code: "coins", stage: 4 }]
            }, {
                units: [{ code: "coins", stage: 3 }]
            }, {
                units: [{ code: "coins", stage: 3 }]
            }, {
                probabilities: [0.5],
                units: [{ code: "coins", stage: 1 }]
            }],
            timeout: "6 hours",
            unmergeable: true,
            breakable: {
                limit: 12
            }
        }, {
            prize: [{
                units: [{ code: "coins", stage: 4 }]
            }, {
                units: [{ code: "coins", stage: 4 }]
            }, {
                probabilities: [0.5],
                units: [{ code: "coins", stage: 2 }]
            }],
            timeout: "4 hours",
            unmergeable: true,
            breakable: {
                limit: 24
            }
        }]
    });

    Families.fruitshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_FRUIT
    });
    Families.resourceshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_RESOURCE
    });
    Families.discountshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_DISCOUNT
    });

    Families.telly = FamiliesHelper.TellyFamily({
        expeditions: ["halloween", "blackfriday", "xmas", "china", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "main"]
    });

    Families.bppointscrystal = FamiliesHelper.BattlePassPointsFamily({
        reward: {
            min: 10,
            max: 50
        }
    });

    Families.bppointsstar = FamiliesHelper.BattlePassPointsFamily({
        reward: {
            min: 25,
            max: 50
        },
        expeditions: ["xmas", "halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "rapunzel", "rapunzel2", "rapunzel3", "main", "china", "easter", "collections"]
    });

    Families.barrel = FamiliesHelper.BarrelFamily();

    Families.unknown = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false
    }, {
        expeditions: "all"
    });

    Families.dr2elf = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: true,
        important: true
    }, {
        type: "drcustomer",
        expeditions: "dragonia2"
    });

    Families.ruins = FamiliesHelper.RuinsFamily({
        price: 40,
        expeditions: ["xmas", "china", "halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "main"]
    });

    Families.seasonchest = FamiliesHelper.ChestFamily("seasonchest", {
        price: [13, 17, 75],
        expeditions: ["dragonia2", "dragonia3", "undersea2", "undersea3", "main"]
    });

    var seasonTotals = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
    for (var month = 0; month < seasonTotals.length; month++) {
        Families["season" + month] = FamiliesHelper.SeasonItemFamily({ month: month, total: seasonTotals[month] });
    }

    Families.dragonblue = FamiliesHelper.ExpeditionHeroFamily({ total: 2, type: "drhero", expeditions: "dragonia" });
    Families.dragongreen = FamiliesHelper.ExpeditionHeroFamily({ total: 4, type: "drhero", expeditions: "dragonia" });
    Families.dragonyellow = FamiliesHelper.ExpeditionHeroFamily({ total: 6, type: "drhero", expeditions: "dragonia" });
    Families.dragonred = FamiliesHelper.ExpeditionHeroFamily({ total: 7, type: "drhero", expeditions: "dragonia" });
    Families.dryeti = FamiliesHelper.ExpeditionHeroFamily({
        total: 8, type: "drhero", expeditions: "dragonia", landmarkDonor: true
    });
    Families.drelf = FamiliesHelper.ExpeditionHeroFamily({ total: 11, type: "drhero", expeditions: "dragonia" });
    Families.dragonpack = FamiliesHelper.ExpeditionHeroFamily({
        total: 1, tank: "pack", type: "drhero", expeditions: "dragonia"
    });
    Families.dragonruby = FamiliesHelper.ExpeditionHeroFamily({
        total: 4, tank: "ruby", type: "drrubyhero", expeditions: "dragonia", price: [49]
    });

    Families.drwood = FamiliesHelper.ExpeditionResourceFamily({
        total: 5, type: "drresource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], prize: "drmagicplant", startBpPoints: 2
    });
    Families.drstone = FamiliesHelper.ExpeditionResourceFamily({
        total: 6, type: "drresource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], prize: "drmagicplant", startBpPoints: 2
    });
    Families.drsapphire = FamiliesHelper.ExpeditionResourceFamily({
        total: 7, type: "drresource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], prize: "drmagicplant", startBpPoints: 3
    });
    Families.drsled = FamiliesHelper.ExpeditionResourceFamily({
        total: 8, type: "drresource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], prize: "drmagicplant", startBpPoints: 4
    });
    Families.drcastle = FamiliesHelper.ExpeditionResourceFamily({
        total: 9, type: "drresource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"], prize: "drmagicplant", startBpPoints: 4
    });

    Families.drwoodsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], resource: "drwood", skipTime: 0, mined: [2, 3, 4], energy: [4, 6, 8, 10, 12]
    });
    Families.drstonesource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], resource: "drstone", skipTime: 1, mined: [2, 3, 4], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.drsapphiresource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], resource: "drsapphire", skipTime: 2, mined: [2, 3, 4], energy: [4, 6, 8, 10, 12, 14, 16]
    });
    Families.drsledsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], resource: "drsled", skipTime: 2, mined: [2, 3, 5], energy: [4, 6, 8, 10, 12, 14, 16, 18]
    });
    Families.drcastlesource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"], resource: "drcastle", skipTime: 3, mined: [2, 3, 5], energy: [4, 6, 8, 10, 12, 14, 16, 18, 20]
    });

    Families.drwoodgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], source: "drwoodsource"
    });
    Families.drstonegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], source: "drstonesource"
    });
    Families.drsapphiregenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], source: "drsapphiresource"
    });
    Families.drsledgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], source: "drsledsource"
    });
    Families.drcastlegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"], source: "drcastlesource"
    });

    Families.drwoodchest = FamiliesHelper.ExpeditionChestFamily({ resource: "drwoodchest", type: "drchest", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"] });
    Families.drstonechest = FamiliesHelper.ExpeditionChestFamily({ resource: "drstonechest", type: "drchest", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"] });
    Families.drsapphirechest = FamiliesHelper.ExpeditionChestFamily({ resource: "drsapphirechest", type: "drchest", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"] });
    Families.drsledchest = FamiliesHelper.ExpeditionChestFamily({ resource: "drsledchest", type: "drchest", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"] });
    Families.drcastlechest = FamiliesHelper.ExpeditionChestFamily({ resource: "drcastlechest", type: "drchest", expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"] });
    Families.drshopchest = FamiliesHelper.ExpeditionChestFamily({
        price: [49, 129, 319],
        resource: "drshopchest",
        type: "drchest",
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.drfreechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "drfreechest",
        type: "drchest",
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });

    Families.drshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA,
        expeditions: ["dragonia"]
    });
    Families.dr2shop = FamiliesHelper.UnitsShopFamily({
        tab: {
            dragonia2: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2,
            dragonia3: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3,
            halloween: ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN,
            blackfriday: ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY,
            xmas: ShopWindow.TABS.UNITS_EXPEDITION_XMAS,
            china: ShopWindow.TABS.UNITS_EXPEDITION_CHINA
        },
        pointOfInterest: true,
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });

    Families.drbattlepass = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 26,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia", "dragonia2"],
        multicell: FamiliesHelper.ShapeSquare
    });

    Families.drgrowing = FamiliesHelper.ExpeditionGrowingFamily({
        type: "drgrowing",
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"],
        energy: [5, 7, 10]
    });

    Families.drhouse = FamiliesHelper.ExpeditionHouseFamily({
        type: "drhouse",
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"],
        stages: [{
            prize: [
                { units: [{ code: "crystal", stage: 3 }] }
            ],
            timeout: "24 hours"
        }, {
            prize: [
                { units: [{ code: "crystal", stage: 3 }] },
                { units: [{ code: "coins", stage: 2 }] },
                { units: [{ code: "drfreechest", stage: 2 }] },
                { units: [{ code: "rubies", stage: 0 }] },
                { units: [{ code: "worker", stage: 4 }] }
            ],
            timeout: "6 hours"
        }, {
            prize: [
                { units: [{ code: "crystal", stage: 3 }] },
                { units: [{ code: "coins", stage: 2 }] },
                { units: [{ code: "drfreechest", stage: 0 }] }
            ],
            timeout: "6 hours"
        }]
    });

    Families.drfactory = FamiliesHelper.SpecialUnitFamily({
        important: true,
        pointOfInterest: true,
        movable: false,
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        toyfactory: true
    }, {
        expeditions: ["dragonia"]
    });
    Families.drrudolf = FamiliesHelper.SpecialUnitFamily({
        price: 1500,
        unmergeable: true,
        pointOfInterest: true,
        rudolf: true
    }, {
        expeditions: "dragonia"
    });
    Families.drgiftaxe = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "drgift", producer: "drgiftgenerator", expeditions: "dragonia", price: [20, undefined, 49]
    });
    Families.drgiftcuirass = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "drgift", producer: "drgiftgenerator", expeditions: "dragonia", price: [20, undefined, 49]
    });
    Families.drgifthelm = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "drgift", producer: "drgiftgenerator", expeditions: "dragonia", price: [20, undefined, 49]
    });
    Families.drgiftshield = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "drgift", producer: "drgiftgenerator", expeditions: "dragonia", price: [20, undefined, 49]
    });

    Families.drportal = FamiliesHelper.SpecialUnitFamily({
        important: true,
        pointOfInterest: true,
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        portal: true
    }, {
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.drgiftgenerator = FamiliesHelper.SpecialUnitFamily({
        price: 5222,
        improver: true
    }, {
        expeditions: "dragonia"
    });

    Families.drmagicplant = FamiliesHelper.ExpeditionMagicPlantFamily({
        total: 10,
        type: "drmagicplant",
        timeout: "6 hours",
        prize: [{
            units: [{ code: "crystal", stage: 1 }]
        }],
        expeditions: ["dragonia", "dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });

    // dragonia2
    Families.dr2dragonblue = FamiliesHelper.FeedableHeroFamily({
        total: 2, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.dr2dragongreen = FamiliesHelper.FeedableHeroFamily({
        total: 4, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.dr2dragonyellow = FamiliesHelper.FeedableHeroFamily({
        total: 6, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.dr2dragonred = FamiliesHelper.FeedableHeroFamily({
        total: 7, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.dr2dragonpurple = FamiliesHelper.FeedableHeroFamily({
        total: 8, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"], landmarkDonor: true
    });
    Families.dr2dragonrainbow = FamiliesHelper.FeedableHeroFamily({
        total: 11, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"]
    });
    Families.dr2dragonbutterfly = FamiliesHelper.FeedableHeroFamily({
        total: 13, type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3"]
    });
    Families.dr2dragonpack = FamiliesHelper.FeedableHeroFamily({
        total: 1, tank: "pack", type: "drhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas"]
    });
    Families.dr2dragonruby = FamiliesHelper.FeedableHeroFamily({
        total: 4, tank: "ruby", type: "drrubyhero", food: { code: "drfruit", stage: 4 }, expeditions: ["dragonia2"], price: [49]
    });

    Families.drpurplecrystal = FamiliesHelper.ExpeditionResourceFamily({
        total: 10, type: "drresource", expeditions: ["dragonia2", "dragonia3"], prize: "drmagicplant", startBpPoints: 4
    });
    Families.drbamboo = FamiliesHelper.ExpeditionResourceFamily({
        total: 11, type: "drresource", expeditions: ["dragonia2", "dragonia3"], prize: "drmagicplant", startBpPoints: 4
    });
    Families.drfeathers = FamiliesHelper.ExpeditionResourceFamily({
        total: 12, type: "drresource", expeditions: ["dragonia2", "dragonia3"], prize: "drmagicplant", startBpPoints: 4
    });

    Families.drpurplecrystalsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia2", "dragonia3"], resource: "drpurplecrystal", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.drfeatherssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia2", "dragonia3"], resource: "drfeathers", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.drbamboosource = FamiliesHelper.ExpeditionSourceFamily({
        type: "drsource", expeditions: ["dragonia2", "dragonia3"], resource: "drbamboo", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });

    Families.drpurplecrystalgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia2", "dragonia3"], source: "drpurplecrystalsource"
    });
    Families.drfeathersgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia2", "dragonia3"], source: "drfeatherssource"
    });
    Families.drbamboogenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "drgenerator", expeditions: ["dragonia2", "dragonia3"], source: "drbamboosource"
    });

    Families.drpurplecrystalchest = FamiliesHelper.ExpeditionChestFamily({ resource: "drpurplecrystalchest", type: "drchest", expeditions: ["dragonia2", "dragonia3"] });
    Families.drfeatherschest = FamiliesHelper.ExpeditionChestFamily({ resource: "drfeatherschest", type: "drchest", expeditions: ["dragonia2", "dragonia3"] });
    Families.drbamboochest = FamiliesHelper.ExpeditionChestFamily({ resource: "drbamboochest", type: "drchest", expeditions: ["dragonia2", "dragonia3"] });

    Families.drfruit = FamiliesHelper.FeedFruitFamily({
        code: "drfruit",
        duration: "6 hour",
        type: "drfruit",
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"],
        prize: [{
            amount: [4, 6],
            units: [{ code: "drfruit", stage: 4 }]
        }, {
            probabilities: [0.5],
            units: [{ code: "drmagicplant", stage: 0 }]
        }]
    });

    Families.drsack = FamiliesHelper.ExpeditionChestFamily({
        price: [49, 99, 149],
        resource: "drsack",
        type: "drchest",
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });

    Families.drtournament = FamiliesHelper.MissionWindowOpener({
        type: "drtournament",
        total: 1,
        mission: Mission.TYPE_SNAIL_FEAST,
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });

    Families.drsnail = FamiliesHelper.SnailFeastFamily({
        total: 16,
        type: "snailfeast",
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"]
    });
    Families.drsnailhouse = FamiliesHelper.SnailHouseFamily({
        amount: 5,
        maxStage: 10,
        expeditions: ["dragonia2", "dragonia3", "halloween", "blackfriday", "xmas", "china"],
        prize: { code: "drsnail" }
    });

    Families.dr2field = FamiliesHelper.ExpeditionFieldFamily({ expeditions: "dragonia2" });

    Families.dr2wheat = FamiliesHelper.DragoniaResourceFamily({ type: "drresource", locators: ["dr2field"], expeditions: "dragonia2" });
    Families.dr2coal = FamiliesHelper.DragoniaResourceFamily({ type: "drresource", locators: ["dr2mine"], expeditions: "dragonia2" });
    Families.dr2barrel = FamiliesHelper.DragoniaResourceFamily({ type: "drresource", locators: ["dr2sawmill"], expeditions: "dragonia2" });

    Families.dr2blacksmith = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        climbable: true,
        customer: true,
        important: true
    }, {
        type: "drcustomer",
        expeditions: "dragonia2",
        total: 10,
        oneViewForStages: true
    });
    Families.dr2airship = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        climbable: true,
        customer: true,
        campaign: true,
        pointOfInterest: true,
        important: true,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        type: "drcustomer",
        expeditions: ["dragonia2"],
        total: 6
    });
    Families.dr2mine = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: true,
        important: true,
        movable: false,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        type: "drcustomer",
        expeditions: "dragonia2"
    });
    Families.dr2sawmill = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: true,
        important: true,
        movable: false,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        type: "drcustomer",
        expeditions: "dragonia2"
    });
    Families.dr2elf = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: true,
        important: true
    }, {
        type: "drcustomer",
        expeditions: "dragonia2"
    });

    Families.dr2treasuremap = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        treasureMap: true
    }, {
        type: "treasuremap",
        expeditions: "dragonia2"
    });

    Families.dr2campaign = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        pointOfInterest: true
    }, {
        type: "campaignorder",
        expeditions: "dragonia2",
        total: 5
    });

    Families.drproducer0a = FamiliesHelper.ExpeditionFieldFamily({ expeditions: "dragonia3" });

    Families.drproducer1a = FamiliesHelper.ExpeditionCustomerFamily({
        type: "drcustomer",
        expeditions: "dragonia3"
    });
    Families.drproducer1b = FamiliesHelper.ExpeditionCustomerFamily({
        type: "drcustomer",
        expeditions: "dragonia3"
    });

    Families.drproducer2a = FamiliesHelper.ExpeditionCustomerFamily({
        multicell: FamiliesHelper.ShapeSquare,
        type: "drcustomer",
        expeditions: "dragonia3"
    });
    Families.drproducer2b = FamiliesHelper.ExpeditionCustomerFamily({
        multicell: FamiliesHelper.ShapeSquare,
        type: "drcustomer",
        expeditions: "dragonia3"
    });

    Families.drproducertrash = FamiliesHelper.ExpeditionCustomerFamily({
        type: "drcustomer",
        expeditions: "dragonia3"
    });

    Families.drproduct0a = FamiliesHelper.ExpeditionProductFamily({
        guide: { name: "dragonia_product_guidewindow", bundle: bundles.dragonia_product_guidewindow },
        locators: ["drproducer0a"],
        type: "drproduct0",
        expeditions: "dragonia3"
    });
    Families.drproduct0b = FamiliesHelper.ExpeditionProductFamily({
        guide: { name: "dragonia_product_guidewindow", bundle: bundles.dragonia_product_guidewindow },
        locators: ["drproducer0a"],
        type: "drproduct0",
        expeditions: "dragonia3"
    });

    Families.drproduct1b = FamiliesHelper.ExpeditionProductFamily({
        guide: { name: "dragonia_product_guidewindow", bundle: bundles.dragonia_product_guidewindow },
        locators: ["drproducer1b"],
        type: "drproduct1",
        expeditions: "dragonia3"
    });
    Families.drproduct1a = FamiliesHelper.ExpeditionProductFamily({
        guide: { name: "dragonia_product_guidewindow", bundle: bundles.dragonia_product_guidewindow },
        locators: ["drproducer1a"],
        type: "drproduct1",
        expeditions: "dragonia3"
    });

    Families.drproduct2a = FamiliesHelper.ExpeditionProductFamily({
        guide: { name: "dragonia_product_guidewindow", bundle: bundles.dragonia_product_guidewindow },
        locators: ["drproducer2a", "drproducer2b"],
        type: "drproduct2",
        expeditions: "dragonia3"
    });

    Families.drcustomermain0 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: "dragonia3"
    });
    Families.drcustomermain1 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        expeditions: "dragonia3"
    });
    Families.drcustomermain2 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        expeditions: "dragonia3"
    });
    Families.drcustomermain3 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: "dragonia3"
    });

    Families.drcustomerbridge1 = FamiliesHelper.ExpeditionBuildingFamily({
        campaign: true,
        expeditions: "dragonia3"
    });

    Families.drbattlepass1 = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.drbattlepass2 = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.drbattlepass3 = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.drbattlepass4 = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.drbattlepass5 = FamiliesHelper.MissionWindowOpener({
        type: "drbattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["dragonia3", "halloween", "blackfriday", "xmas", "china"],
        multicell: FamiliesHelper.ShapeSquare
    });

    // easter
    Families.eaeggssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "easource", expeditions: ["easter"], resource: "eaeggs", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.earabbitsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "easource", expeditions: ["easter"], resource: "earabbit", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.eachicksource = FamiliesHelper.ExpeditionSourceFamily({
        type: "easource", expeditions: ["easter"], resource: "eachick", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.eabreadsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "easource", expeditions: ["easter"], resource: "eabread", mined: [1, 1], energyByStage: [20, 30]
    });

    Families.eaeggs = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "earesource", expeditions: ["easter"], guideOptions: { name: "eaprince_guide_window", bundle: bundles.eaprince_guide_window }
    });
    Families.earabbit = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "earesource", expeditions: ["easter"], guideOptions: { name: "eaprince_guide_window", bundle: bundles.eaprince_guide_window }
    });
    Families.eachick = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "earesource", expeditions: ["easter"], guideOptions: { name: "eaprince_guide_window", bundle: bundles.eaprince_guide_window }
    });
    Families.eabread = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "earesource", expeditions: ["easter"], guideOptions: { name: "eaprince_guide_window", bundle: bundles.eaprince_guide_window }
    });

    Families.eabaskets = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "eaorder", expeditions: ["easter"], guideOptions: { name: "eathrone_guide_window", bundle: bundles.eathrone_guide_window }
    });
    Families.eateaset = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "eaorder", expeditions: ["easter"], guideOptions: { name: "eathrone_guide_window", bundle: bundles.eathrone_guide_window }
    });
    Families.eabell = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "eaorder", expeditions: ["easter"], guideOptions: { name: "eathrone_guide_window", bundle: bundles.eathrone_guide_window }
    });
    Families.eatulips = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "eaorder", expeditions: ["easter"], guideOptions: { name: "eathrone_guide_window", bundle: bundles.eathrone_guide_window }
    });

    Families.eaprince = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: {
            guide: {
                name: "eaprince_guide_window",
                bundle: bundles.eaprince_guide_window
            }
        },
        important: true
    }, {
        type: "eacustomer",
        expeditions: ["easter"]
    });
    Families.eathrone = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: {
            guide: {
                name: "eathrone_guide_window",
                bundle: bundles.eathrone_guide_window
            }
        },
        pointOfInterest: true,
        important: true,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        type: "eacustomer",
        expeditions: ["easter"]
    });
    Families.eaunicorn = FamiliesHelper.RapunzelHeroFamily({
        total: 10, type: "eahero", expeditions: ["easter"], landmarkDonor: true
    });
    Families.eashopchest = FamiliesHelper.ExpeditionChestFamily({
        price: [99],
        resource: "eashopchest",
        type: "eachest",
        expeditions: ["easter"]
    });
    Families.eafreechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "eafreechest",
        type: "eachest",
        expeditions: ["easter"]
    });
    Families.eashop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_EXPEDITION_EASTER,
        expeditions: ["easter"]
    });

    Families.easourcegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "easourcegenerator",
        expeditions: ["easter"],
        targetType: "easource",
        duration: "20 minutes"
    });

    Families.eacustomerbridge0a = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        squeezable: false,
        expeditions: ["easter"]
    });
    Families.eacustomerbridge0b = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        squeezable: false,
        expeditions: ["easter"]
    });
    Families.eacustomermain0a = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["easter"]
    });
    Families.eacustomermain0b = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["easter"]
    });
    Families.eaproduct0 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["eaproducer0"],
        type: "eaproduct0",
        expeditions: ["easter"]
    });
    Families.eaproducer0 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        expeditions: ["easter"]
    });

    // rapunzel
    Families.rpdollsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel", "rapunzel2"], resource: "rpdoll", mined: [1, 1], energyByStage: [20, 30]
    });

    Families.rpchameleonsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel", "rapunzel2"], resource: "rpchameleon", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpjewelrysource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel", "rapunzel2"], resource: "rpjewelry", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpdresssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel", "rapunzel2"], resource: "rpdress", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpspidersource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel2"], resource: "rpspider", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpmushroomsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel2"], resource: "rpmushroom", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpbatsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel2"], resource: "rpbat", mined: [1, 1], energyByStage: [20, 30]
    });

    Families.rpdoll = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpchameleon = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpjewelry = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpdress = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpspider = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel2"]
    });
    Families.rpmushroom = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel2"]
    });
    Families.rpbat = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", guideOptions: { name: "rpprince_guide_window", bundle: bundles.rpprince_guide_window }, expeditions: ["rapunzel2"]
    });

    Families.rpperfume = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "rporder", expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpcandle = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "rporder", expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpcomb = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "rporder", expeditions: ["rapunzel", "rapunzel2"]
    });
    Families.rpanimals = FamiliesHelper.RapunzelOrderFamily({
        total: 4, type: "rporder", expeditions: ["rapunzel", "rapunzel2"]
    });

    Families.rprapunzel = FamiliesHelper.RapunzelHeroFamily({
        total: 8, type: "rphero", expeditions: ["rapunzel"], landmarkDonor: true
    });

    Families.rprapuncust = FamiliesHelper.SpecialUnitFamily({
        customer: true
    }, {
        type: "rpcustomer",
        expeditions: ["rapunzel2"],
        total: 2
    });

    Families.rptelescope = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        pointOfInterest: true,
        customer: true,
        climbable: true,
        campaign: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        total: 2
    });

    Families.rpcampaign = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        pointOfInterest: true
    }, {
        type: "campaignorder",
        expeditions: "rapunzel2",
        total: 7
    });

    Families.rpcentaur = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        climbable: true,
        customer: true,
        pointOfInterest: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        oneViewForStages: true,
        total: 7
    });

    Families.rppress = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false,
        pointOfInterest: true,
        multicell: FamiliesHelper.ShapeSquare,
        climbable: true,
        customer: true,
        campaign: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        total: 3
    });

    Families.rpcrane = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false,
        pointOfInterest: true,
        multicell: FamiliesHelper.ShapeSquare,
        climbable: true,
        customer: true,
        campaign: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        total: 3
    });

    Families.rptrolley = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false,
        pointOfInterest: true,
        multicell: FamiliesHelper.ShapeSquare,
        climbable: true,
        customer: true,
        campaign: true,
        hasShowUp: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        total: 5
    });

    Families.rpdoorway = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false,
        pointOfInterest: true,
        multicell: FamiliesHelper.ShapeSquare,
        climbable: true,
        customer: true,
        campaign: true,
        important: true
    }, {
        type: "rpcustomer",
        expeditions: "rapunzel2",
        total: 5
    });

    Families.rpprince = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: {
            guide: {
                name: "rpprince_guide_window",
                bundle: bundles.rpprince_guide_window
            }
        },
        important: true
    }, {
        type: "rpcustomer",
        expeditions: ["rapunzel"]
    });

    Families.rpprincehero = FamiliesHelper.RapunzelHeroFamily({
        total: 8, type: "rphero", expeditions: ["rapunzel2"], landmarkDonor: true
    });

    Families.rpthrone = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        customer: {
            guide: {
                name: "rpthrone_guide_window",
                bundle: bundles.rpthrone_guide_window
            }
        },
        pointOfInterest: true,
        important: true,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        type: "rpcustomer",
        expeditions: ["rapunzel", "rapunzel2"]
    });

    Families.rpshopchest = FamiliesHelper.ExpeditionChestFamily({
        price: [99],
        resource: "rpshopchest",
        type: "rpchest",
        expeditions: ["rapunzel", "rapunzel2", "rapunzel3"]
    });
    Families.rpfreechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "rpfreechest",
        type: "rpchest",
        expeditions: ["rapunzel", "rapunzel2", "rapunzel3"]
    });

    Families.rpshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        expeditions: ["rapunzel", "rapunzel2", "rapunzel3"]
    });

    Families.rpsourcegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "rpsourcegenerator",
        expeditions: ["rapunzel", "rapunzel2", "rapunzel3"],
        targetType: "rpsource",
        duration: "20 minutes"
    });

    Families.rpplatesource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel3"], resource: "rpplate", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpcutlerysource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel3"], resource: "rpcutlery", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rppanssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel3"], resource: "rppans", mined: [1, 1], energyByStage: [20, 30]
    });
    Families.rpmugssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "rpsource", expeditions: ["rapunzel3"], resource: "rpmugs", mined: [1, 1], energyByStage: [20, 30]
    });

    Families.rpplate = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", mergeBpPoints: [10, 30, 60, 100], expeditions: ["rapunzel3"]
    });
    Families.rpcutlery = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", mergeBpPoints: [15, 50, 90, 120], expeditions: ["rapunzel3"]
    });
    Families.rppans = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", mergeBpPoints: [20, 80, 200, 250], expeditions: ["rapunzel3"]
    });
    Families.rpmugs = FamiliesHelper.RapunzelResourceFamily({
        total: 4, type: "rpresource", mergeBpPoints: [30, 120, 200, 350], expeditions: ["rapunzel3"]
    });

    Families.rpproduct0 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["rpproducer0"],
        type: "rpproduct0",
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproduct1 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["rpproducer1"],
        type: "rpproduct1",
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproduct2 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["rpproducer2"],
        type: "rpproduct2",
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproduct3 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["rpproducer3"],
        type: "rpproduct3",
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "adventure2"]
    });
    Families.rpproduct4 = FamiliesHelper.ExpeditionProductFamily({
        locators: ["rpproducer4"],
        type: "rpproduct4",
        expeditions: ["adventure2"]
    });
    Families.rpproducer0 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        hasShowUp: true,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproducer1 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        hasShowUp: true,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproducer2 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        hasShowUp: true,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpproducer3 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        hasShowUp: true,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "adventure2"]
    });
    Families.rpproducer4 = FamiliesHelper.ExpeditionCustomerFamily({
        type: "rpcustomer",
        hasShowUp: true,
        expeditions: ["adventure2"]
    });
    Families.rpcustomerbridge0 = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        squeezable: false,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomerbridge1 = FamiliesHelper.ExpeditionBuildingFamily({
        squeezable: false,
        multicell: {
            xmas: FamiliesHelper.ShapeSquare,
            china: FamiliesHelper.ShapeSquare
        },
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomerbridge2 = FamiliesHelper.ExpeditionBuildingFamily({
        squeezable: false,
        multicell: {
            xmas: FamiliesHelper.ShapeSquare
        },
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomerbridge3 = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        squeezable: false,
        expeditions: ["rapunzel3", "undersea3", "halloween", "blackfriday", "xmas", "adventure2"]
    });
    Families.rpcustomerbridge4 = FamiliesHelper.ExpeditionBuildingFamily({
        multicell: FamiliesHelper.ShapeSquare,
        squeezable: false,
        expeditions: ["adventure2"]
    });
    Families.rpcustomermain0 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomermain0a = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3", "undersea3", "halloween"],
        hasShowUpStage: true
    });
    Families.rpcustomermain0b = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain1 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomermain1a = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3", "undersea3", "halloween"]
    });
    Families.rpcustomermain1b = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain1c = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain2 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["blackfriday", "xmas", "china", "adventure2"]
    });
    Families.rpcustomermain2a = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3", "undersea3", "halloween"]
    });
    Families.rpcustomermain2b = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain2c = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain2d = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain3 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        hasShowUp: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["blackfriday", "xmas", "adventure2"]
    });
    Families.rpcustomermain3a = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        hasShowUp: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3", "undersea3", "halloween"]
    });
    Families.rpcustomermain3b = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain3c = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain3d = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["rapunzel3"]
    });
    Families.rpcustomermain4 = FamiliesHelper.ExpeditionBuildingFamily({
        landmarkDonor: true,
        multicell: FamiliesHelper.ShapeSquare,
        expeditions: ["adventure2"]
    });

    Families.sealisa = FamiliesHelper.ExpeditionHeroFamily({ total: 2, type: "seahero", expeditions: "undersea" });
    Families.sealumberjack = FamiliesHelper.ExpeditionHeroFamily({ total: 4, type: "seahero", expeditions: "undersea" });
    Families.sealittlegirl = FamiliesHelper.ExpeditionHeroFamily({ total: 6, type: "seahero", expeditions: "undersea" });
    Families.seagranny = FamiliesHelper.ExpeditionHeroFamily({ total: 7, type: "seahero", expeditions: "undersea" });
    Families.seafisherman = FamiliesHelper.ExpeditionHeroFamily({
        total: 8, type: "seahero", expeditions: "undersea", landmarkDonor: true
    });
    Families.seascout = FamiliesHelper.ExpeditionHeroFamily({ total: 11, type: "seahero", expeditions: "undersea" });

    Families.walrusruby = FamiliesHelper.ExpeditionHeroFamily({
        total: 4, tank: "ruby", type: "searubyhero", expeditions: "undersea", price: [49]
    });
    Families.underseapack = FamiliesHelper.ExpeditionHeroFamily({
        total: 1, tank: "pack", type: "seahero", expeditions: "undersea"
    });

    Families.sea2lisa = FamiliesHelper.FeedableHeroFamily({
        total: 2, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0, images: "sealisa"
    });
    Families.sea2lumberjack = FamiliesHelper.FeedableHeroFamily({
        total: 4, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.30, images: "sealumberjack"
    });
    Families.sea2littlegirl = FamiliesHelper.FeedableHeroFamily({
        total: 6, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.25, images: "sealittlegirl"
    });
    Families.sea2granny = FamiliesHelper.FeedableHeroFamily({
        total: 7, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.20, images: "seagranny"
    });
    Families.sea2fisherman = FamiliesHelper.FeedableHeroFamily({
        total: 8, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.125, landmarkDonor: true, images: "seafisherman"
    });
    Families.sea2scout = FamiliesHelper.FeedableHeroFamily({
        total: 11, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.075, images: "seascout"
    });
    Families.sea2capitannemo = FamiliesHelper.FeedableHeroFamily({
        total: 13, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], probability: 0.05, images: "seacapitannemo"
    });

    Families.sea2walrusruby = FamiliesHelper.FeedableHeroFamily({
        total: 4, type: "searubyhero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], price: [49], images: "walrusruby"
    });
    Families.sea2pack = FamiliesHelper.FeedableHeroFamily({
        total: 1, type: "seahero", food: { code: "seastrawberry", stage: 4 }, expeditions: ["undersea2", "undersea3"], images: "underseapack"
    });

    Families.seacrab = FamiliesHelper.ExpeditionResourceFamily({
        total: 5, type: "searesource", expeditions: ["undersea", "undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });
    Families.seafishes = FamiliesHelper.ExpeditionResourceFamily({
        total: 6, type: "searesource", expeditions: ["undersea", "undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });
    Families.seashark = FamiliesHelper.ExpeditionResourceFamily({
        total: 7, type: "searesource", expeditions: ["undersea", "undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 3
    });
    Families.seaoctopus = FamiliesHelper.ExpeditionResourceFamily({
        total: 8, type: "searesource", expeditions: ["undersea", "undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 4
    });
    Families.seahorse = FamiliesHelper.ExpeditionResourceFamily({
        total: 9, type: "searesource", expeditions: ["undersea", "undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });
    Families.sealochness = FamiliesHelper.ExpeditionResourceFamily({
        total: 10, type: "searesource", expeditions: ["undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });
    Families.seaturtle = FamiliesHelper.ExpeditionResourceFamily({
        total: 11, type: "searesource", expeditions: ["undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });
    Families.seashrimps = FamiliesHelper.ExpeditionResourceFamily({
        total: 12, type: "searesource", expeditions: ["undersea2", "undersea3"], prize: "seamagicplant", startBpPoints: 2
    });

    Families.seacrabsource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seacrab", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seafishessource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seafishes", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seasharksource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seashark", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seaoctopussource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seaoctopus", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seahorsesource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seahorse", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.sealochnesssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea2", "undersea3"], resource: "sealochness", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seaturtlesource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea2", "undersea3"], resource: "seaturtle", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });
    Families.seashrimpssource = FamiliesHelper.ExpeditionSourceFamily({
        type: "seasource", expeditions: ["undersea2", "undersea3"], resource: "seashrimps", mined: [2, 4, 6], energy: [4, 6, 8, 10, 12, 14]
    });

    Families.seacrabgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seacrab"
    });
    Families.seafishesgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seafishes"
    });
    Families.seasharkgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seashark"
    });
    Families.seaoctopusgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seaoctopus"
    });
    Families.seahorsegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea", "undersea2", "undersea3"], resource: "seahorse"
    });
    Families.sealochnessgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea2", "undersea3"], resource: "sealochness"
    });
    Families.seaturtlegenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea2", "undersea3"], resource: "seaturtle"
    });
    Families.seashrimpsgenerator = FamiliesHelper.ExpeditionGeneratorFamily({
        type: "seagenerator", expeditions: ["undersea2", "undersea3"], resource: "seashrimps"
    });

    Families.seacrabchest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seacrabchest", type: "seachest", expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seafisheschest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seafisheschest", type: "seachest", expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seasharkchest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seasharkchest", type: "seachest", expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seaoctopuschest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seaoctopuschest", type: "seachest", expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seahorsechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seahorsechest", type: "seachest", expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.sealochnesschest = FamiliesHelper.ExpeditionChestFamily({
        resource: "sealochnesschest", type: "seachest", expeditions: ["undersea2", "undersea3"]
    });
    Families.seaturtlechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seaturtlechest", type: "seachest", expeditions: ["undersea2", "undersea3"]
    });
    Families.seashrimpschest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seashrimpschest", type: "seachest", expeditions: ["undersea2", "undersea3"]
    });

    Families.seashopchest = FamiliesHelper.ExpeditionChestFamily({
        price: [49, 129, 319],
        resource: "seashopchest",
        type: "seachest",
        expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seafreechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "seafreechest",
        type: "seachest",
        expeditions: ["undersea", "undersea2", "undersea3"]
    });
    Families.seasack = FamiliesHelper.ExpeditionChestFamily({
        price: [49, 99, 149],
        resource: "seasack",
        type: "seachest",
        expeditions: ["undersea2", "undersea3"]
    });

    Families.seashop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        expeditions: ["undersea", "undersea2", "undersea3"]
    });

    Families.seamagicplant = FamiliesHelper.ExpeditionMagicPlantFamily({
        total: 10,
        type: "seamagicplant",
        timeout: "6 hours",
        prize: [{
            units: [{ code: "crystal", stage: 1 }]
        }],
        expeditions: ["undersea", "undersea2", "undersea3"]
    });

    Families.seabattlepass = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 26,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: ["undersea", "undersea2"],
        multicell: FamiliesHelper.ShapeSquare
    });

    Families.seagrowing = FamiliesHelper.ExpeditionGrowingFamily({
        type: "seagrowing",
        expeditions: ["undersea"]
    });

    Families.seahouse = FamiliesHelper.ExpeditionHouseFamily({
        type: "seahouse",
        expeditions: ["undersea", "undersea2", "undersea3"],
        stages: [{
            prize: [
                { units: [{ code: "crystal", stage: 3 }] }
            ],
            timeout: "24 hours"
        }, {
            prize: [
                { units: [{ code: "crystal", stage: 3 }] },
                { units: [{ code: "coins", stage: 2 }] },
                { units: [{ code: "seafreechest", stage: 2 }] },
                { units: [{ code: "rubies", stage: 0 }] },
                { units: [{ code: "worker", stage: 4 }] }
            ],
            timeout: "6 hours"
        }, {
            prize: [
                { units: [{ code: "crystal", stage: 3 }] },
                { units: [{ code: "coins", stage: 2 }] },
                { units: [{ code: "seafreechest", stage: 0 }] }
            ],
            timeout: "6 hours"
        }]
    });

    Families.seafactory = FamiliesHelper.SpecialUnitFamily({
        important: true,
        pointOfInterest: true,
        movable: false,
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        toyfactory: true
    }, {
        expeditions: "undersea"
    });
    Families.searudolf = FamiliesHelper.SpecialUnitFamily({
        price: 1500,
        unmergeable: true,
        pointOfInterest: true,
        rudolf: true
    }, {
        expeditions: "undersea"
    });
    Families.seaanchor = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "seagift", producer: "seagiftgenerator", expeditions: "undersea", price: [20, undefined, 49]
    });
    Families.seawheel = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "seagift", producer: "seagiftgenerator", expeditions: "undersea", price: [20, undefined, 49]
    });
    Families.seaspyglass = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "seagift", producer: "seagiftgenerator", expeditions: "undersea", price: [20, undefined, 49]
    });
    Families.seapiratehook = FamiliesHelper.ExpeditionGiftFamily({
        total: 6, type: "seagift", producer: "seagiftgenerator", expeditions: "undersea", price: [20, undefined, 49]
    });

    Families.seaportal = FamiliesHelper.SpecialUnitFamily({
        important: true,
        pointOfInterest: true,
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        portal: true
    }, {
        expeditions: ["undersea", "undersea2", "undersea3"]
    });

    Families.seagiftgenerator = FamiliesHelper.SpecialUnitFamily({
        price: 5222,
        improver: true
    }, {
        expeditions: "undersea"
    });

    Families.seasnail = FamiliesHelper.SnailFeastFamily({
        total: 16, type: "snailfeast", expeditions: ["undersea2", "undersea3"]
    });

    Families.seatournament = FamiliesHelper.MissionWindowOpener({
        type: "seatournament",
        total: 1,
        mission: Mission.TYPE_SNAIL_FEAST,
        expeditions: ["undersea2", "undersea3"],
        multicell: FamiliesHelper.ShapeSquare
    });

    Families.seastrawberry = FamiliesHelper.FeedFruitFamily({
        code: "seastrawberry",
        type: "seafruit",
        duration: "6 hour",
        expeditions: ["undersea2", "undersea3"],
        prize: [{
            amount: [4, 6],
            units: [{ code: "seastrawberry", stage: 4 }]
        }, {
            probabilities: [0.5],
            units: [{ code: "seamagicplant", stage: 0 }]
        }]
    });

    Families.seasnailhouse = FamiliesHelper.SnailHouseFamily({
        amount: 5,
        maxStage: 10,
        expeditions: ["undersea2", "undersea3"],
        prize: { code: "seasnail" },
        missionType: Mission.TYPE_UNDERSEA2_EXPEDITION
    });

    Families.energy = FamiliesHelper.ValuableFamily("energy", {
        amounts: [3, 10, 30],
        exp: [0, 1, 2],
        collectible: true,
        expeditions: ["xmas", "china", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "rapunzel", "rapunzel2",
            "rapunzel3", "main", "easter", "china", "adventure", "adventure2", "halloween", "blackfriday"]
    });
    Families.energycup = FamiliesHelper.EnergyCupFamily(3, { price: [30, 91, 270] });
    Families.coinscup = FamiliesHelper.CoinsCupFamily(3, { price: [9, 27, 52] });
    Families.caravanbox = FamiliesHelper.BoxFamily(3, { price: [35, 105, 315] });
    Families.caravanship = FamiliesHelper.ShipFamily();

    Families.cinema = FamiliesHelper.CinemaFamily(4, {
        tab: ShopWindow.TABS.UNITS_ADS,
        vip_tab: ShopWindow.TABS.UNITS_ADS_VIP,
        undecided_tab: ShopWindow.TABS.UNITS_ADS_UNDECIDED
    });

    Families.landmark = FamiliesHelper.LandMarkUnitFamily({
        total: 23,
        type: "landmark",
        expeditions: ["main"],
        extraBundles: ["halloween", "blackfriday", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "undersea3", "xmas",
            "china", "rapunzel", "rapunzel2", "rapunzel3", "easter", "china", "adventure", "adventure2"],
        lazy: true
    });

    Families.landmarkpreview = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        movable: false,
        pointOfInterest: true,
        lazy: true,
        multicell: FamiliesHelper.ShapeSquare
    }, {
        total: Families.landmark.units.length,
        images: "landmark",
        expeditions: ["china", "easter"]
    });

    Families.landmarkspot = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        multicell: FamiliesHelper.ShapeSquare,
        movable: false
    }, {
        type: "landmarkspot",
        expeditions: ["main", "collections", "china", "easter"]
    });

    Families.bank = FamiliesHelper.BankFamily();

    // collections;
    Families.cllandmark = FamiliesHelper.LandMarkUnitFamily({
        total: 10,
        type: "landmark",
        expeditions: ["collections"]
    });

    Families.clhydra = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.cljapanleon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clsalamandra = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clgriffin = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.cldeerdragon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clfishdragon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clgorgon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clphoenix = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clkitsune = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clminotaur = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clbeedragon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clsnowbeast = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clsnowdragon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.cllongneck = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clsnail = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.cltechnosphinx = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clmonkeyking = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clbunnydragon = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });
    Families.clzebra = FamiliesHelper.CollectionAnimalFamily({
        total: 5,
        type: "clpet"
    });

    Families.clcyclops = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.cldemon = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clcrystaldragon = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clmanticore = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clorc = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clkoidragon = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clvasilisk = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clowldragon = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.cltirex = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clmantis = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clray = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.clbuckbeak = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    Families.cllavadragon = FamiliesHelper.CollectionAnimalFamily({
        total: 6,
        type: "clpetrare"
    });
    if (cleverapps.config.debugMode) {
        Families.clsheepy = FamiliesHelper.CollectionAnimalFamily({
            total: 6,
            type: "clpetrare"
        });
        Families.clchubby = FamiliesHelper.CollectionAnimalFamily({
            total: 6,
            type: "clpetrare"
        });
        Families.clplateddragon = FamiliesHelper.CollectionAnimalFamily({
            total: 5,
            type: "clpet"
        });
        Families.clanglerfishdragon = FamiliesHelper.CollectionAnimalFamily({
            total: 5,
            type: "clpet"
        });
        Families.clplantdragon = FamiliesHelper.CollectionAnimalFamily({
            total: 7,
            type: "clpetlegend"
        });
        Families.clfiredragon = FamiliesHelper.CollectionAnimalFamily({
            total: 7,
            type: "clpetlegend"
        });
        Families.clfruitdragon = FamiliesHelper.CollectionAnimalFamily({
            total: 7,
            type: "clpetlegend"
        });
    }
    Families.clcerberus = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clchinesedragon = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clmothcat = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clmushroomdragon = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clent = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clchameleon = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clpegasus = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clbat = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clfrog = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clturtle = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });
    Families.clwyvern = FamiliesHelper.CollectionAnimalFamily({
        total: 7,
        type: "clpetlegend"
    });

    Families.clnarrator = FamiliesHelper.SpecialUnitFamily({
        unmergeable: true,
        important: true,
        guide: ExpeditionWandsGuideOptions
    }, {
        type: "clnarrator",
        expeditions: ["collections"]
    });
    Families.clpawchest = FamiliesHelper.ExpeditionChestFamily({
        resource: "clpawchest",
        type: "clchest",
        expeditions: ["collections"],
        animation: false,
        radius: 15,
        skipCheckScreen: true
    });
    Families.clsimplechest = FamiliesHelper.ExpeditionChestFamily({
        resource: "clsimplechest",
        type: "clchest",
        expeditions: ["collections"],
        radius: 15,
        skipCheckScreen: true
    });
    Families.clpaychest = FamiliesHelper.ExpeditionChestFamily({
        resource: "clpaychest",
        type: "clchest",
        expeditions: ["collections"],
        radius: 15,
        skipCheckScreen: true
    });
    Families.clsack = FamiliesHelper.ExpeditionChestFamily({
        price: [49],
        resource: "clsack",
        type: "clchest",
        expeditions: ["collections"],
        animation: false
    });
    Families.clstrawberry = FamiliesHelper.FeedFruitFamily({
        code: "clstrawberry",
        type: "clfruit",
        duration: "6 hour",
        time: "0 seconds",
        expeditions: ["collections"],
        prize: [{
            amount: [4, 6],
            units: [{ code: "clstrawberry", stage: 4 }]
        }]
    });
    Families.clshop = FamiliesHelper.UnitsShopFamily({
        tab: ShopWindow.TABS.UNITS_EXPEDITION_COLLECTIONS,
        expeditions: ["collections"]
    });

    if (cleverapps.config.debugMode) {
        Families.debugunit = FamiliesHelper.SpecialUnitFamily({
            info: {
                description: "Units.debugunit.description"
            }
        }, {
            expeditions: ["main"]
        });
    }

    Families.advgift = FamiliesHelper.AdventurePrizesFamily([{
        unlock: "3 minutes",
        prize: [{ resource: "hard", amount: 1 }, { resource: "energy", amount: 5 }]
    }, {
        unlock: "5 minutes",
        prize: { resource: "energy", amount: 10 }
    }, {
        unlock: "5 minutes",
        prize: { resource: "energy", amount: 10 }
    }], {
        type: "advprize",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advchest = FamiliesHelper.AdventurePrizesFamily([{
        unlock: "8 minutes",
        prize: { resource: "energy", amount: 15 }
    }, {
        unlock: "10 minutes",
        prize: { resource: "hard", amount: 5 }
    }], {
        type: "advprize",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advpile = FamiliesHelper.AdventurePrizesFamily([{
        prize: { ingredient: "advdoubloon", amount: 1 }
    }, {
        prize: { ingredient: "advlog", amount: [1, 4] }
    }, {
        prize: { ingredient: "advstone", amount: [1, 2] }
    }, {
        prize: { ingredient: "advgrass", amount: [2, 5] }
    }, {
        prize: [
            { ingredient: "advblueberry", amount: 1 },
            { ingredient: "advlychee", amount: 2 },
            { ingredient: "advbanana", amount: 2 }
        ]
    }], {
        type: "advprize",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advrush = FamiliesHelper.AdventurePrizesFamily([{
        unlock: "1 minutes",
        prize: { resource: "energy", amount: 3 }
    }, {
        unlock: "1 minutes",
        prize: { resource: "energy", amount: 3 }
    }, {
        unlock: "1 minutes",
        prize: { resource: "energy", amount: 3 }
    }, {
        unlock: "3 minutes",
        prize: { resource: "energy", amount: 5 }
    }, {
        unlock: "3 minutes",
        prize: { resource: "energy", amount: 5 }
    }, {
        unlock: "3 minutes",
        prize: { resource: "energy", amount: 10 }
    }, {
        unlock: "3 minutes",
        prize: [
            { resource: "advgrass", amount: 1 },
            { resource: "advleaves", amount: 1 },
            { resource: "advadvlog", amount: 1 },
            { resource: "advstick", amount: 1 }
        ]
    }], {
        type: "advprize",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advfireplace = FamiliesHelper.AdventurePrizesFamily({
        unlock: "3 minutes",
        prize: { ingredient: "advstone", amount: 3 }
    }, {
        multicell: FamiliesHelper.ShapeSquare,
        type: "advprize",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advshrub = FamiliesHelper.AdventureLeveledSourceFamily([{
        energy: 9,
        prizes: { advgrass: 3 }
    }, {
        energy: 0,
        prizes: { energy: 1 }
    }], {
        type: "advsource",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advrock = FamiliesHelper.AdventureLeveledSourceFamily([{
        energy: 11,
        prizes: { advstone: 1, advblueberry: 1 }
    }, {
        energy: 19,
        prizes: { advstone: 1, advblueberry: 1 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 27,
        prizes: { advstone: 2, advblueberry: 2 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 35,
        prizes: { advstone: 3, advblueberry: 2 },
        multicell: FamiliesHelper.ShapeSquare
    }], {
        workingAnimation: "stone",
        type: "advsource",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advlawn = FamiliesHelper.AdventureSourceFamily([{
        energy: 4,
        prizes: { advgrass: 1, advblueberry: 1 }
    }, {
        energy: 4,
        prizes: { advgrass: 1, advblueberry: 1 }
    }, {
        energy: 5,
        prizes: { advgrass: 1, advblueberry: 1 }
    }], {
        type: "advsource",
        expeditions: ["adventure", "adventure2"]
    });

    Families.advbush = FamiliesHelper.AdventureSourceFamily([{
        energy: 5,
        prizes: { advstick: 1 }
    }, {
        energy: 12,
        prizes: { advstick: 2 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 12,
        prizes: { advstick: 2, advlychee: 1 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 11,
        prizes: { advstick: 2 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 5,
        prizes: { advstick: 2, advblueberry: 3 }
    }, {
        energy: 12,
        prizes: { advstick: 2 }
    }, {
        energy: 6,
        prizes: { advgrass: 1, advlychee: 1 }
    }, {
        energy: 14,
        prizes: { advstick: 1, advleaves: 2, advbanana: 2 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 5,
        prizes: { advleaves: 2, advbanana: 1 }
    }], {
        type: "advsource",
        expeditions: ["adventure", "adventure2"]
    });

    if (cleverapps.config.debugMode) {
        Families.advflower = FamiliesHelper.AdventureSourceFamily([{
            energy: 5,
            prizes: { advgrass: 1, advlychee: 1 }
        }, {
            energy: 5,
            prizes: { advgrass: 1, advlychee: 1 }
        }, {
            energy: 5,
            prizes: { advbalsam: 1 }
        }, {
            energy: 5,
            prizes: { advstick: 1, advleaves: 2, advbanana: 1 }
        }], {
            type: "advsource",
            expeditions: ["adventure2"]
        });
    }

    Families.advtree = FamiliesHelper.AdventureSourceFamily([{
        energy: 19,
        prizes: { advlog: 3, advstick: 1 }
    }, {
        energy: 17,
        prizes: { advlog: 3 }
    }, {
        energy: 19,
        prizes: { advlog: 3, advleaves: 3 }
    }, {
        energy: 22,
        prizes: { advlog: 3, advstick: 1 }
    }, {
        energy: 37,
        prizes: { advlog: 6, advstick: 4 },
        multicell: FamiliesHelper.ShapeSquare
    }, {
        energy: 10,
        prizes: { advorange: 1 }
    }], {
        type: "advsource",
        pointOfInterest: true,
        expeditions: ["adventure", "adventure2"]
    });

    if (cleverapps.config.debugMode) {
        Families.advpalmtree = FamiliesHelper.AdventureSourceFamily([{
            energy: 19,
            prizes: { advleaves: 2, advlog: 1, advbanana: 1 }
        }, {
            energy: 17,
            prizes: { advleaves: 2, advlog: 2, advbanana: 2 }
        }, {
            energy: 19,
            prizes: { advleaves: 2, advlog: 3, advbanana: 2 }
        }, {
            energy: 22,
            prizes: { advlog: 2, advstick: 2, advlychee: 2 }
        }, {
            energy: 37,
            prizes: { advlog: 4, advstick: 3, advlychee: 3 },
            multicell: FamiliesHelper.ShapeSquare
        }, {
            energy: 22,
            prizes: { advlog: 2, advstick: 1, advlychee: 1 }
        }], {
            type: "advsource",
            pointOfInterest: true,
            expeditions: ["adventure2"]
        });
    }

    Families.advdynamite = FamiliesHelper.SpecialUnitFamily({
        dynamite: { radius: 3 }
    }, { expeditions: ["adventure", "adventure2"] });

    Families.advcustomerbridge0 = FamiliesHelper.AdventureCustomerFamily({
        stopsfog: false,
        type: "advcustomer",
        expeditions: "adventure"
    });
    Families.advcustomerbridge1 = FamiliesHelper.AdventureCustomerFamily({
        multicell: FamiliesHelper.ShapeXArc,
        composite: [{
            x: -2, y: 0, animation: "above"
        }],
        type: "advcustomer",
        expeditions: "adventure"
    });
    Families.advcustomerbridge2 = FamiliesHelper.AdventureCustomerFamily({
        lovesGround: true,
        composite: [{
            x: -1, y: 5, animation: "above"
        }],
        multicell: FamiliesHelper.Shape1x6,
        type: "advcustomer",
        expeditions: "adventure"
    });

    Families.advcustomermain1 = FamiliesHelper.AdventureCustomerFamily({
        multicell: FamiliesHelper.Shape3x3,
        type: "advcustomer",
        expeditions: "adventure"
    });
    Families.advcustomermain2 = FamiliesHelper.AdventureCustomerFamily({
        finishHint: true,
        type: "advcustomer",
        expeditions: "adventure"
    });

    // undersea3
    Families.seabattlepass1 = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: "undersea3",
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.seabattlepass2 = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: "undersea3",
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.seabattlepass3 = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: "undersea3",
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.seabattlepass4 = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: "undersea3",
        multicell: FamiliesHelper.ShapeSquare
    });
    Families.seabattlepass5 = FamiliesHelper.MissionWindowOpener({
        type: "seabattlepass",
        total: 6,
        pointOfInterest: false,
        mission: Mission.TYPE_EXPEDITION_PASS,
        expeditions: "undersea3",
        multicell: FamiliesHelper.ShapeSquare
    });

    // blackfriday
    Families.dr2dragoncup = FamiliesHelper.SimpleCupFamily({
        type: "expeditioncup",
        expeditions: ["blackfriday"],
        units: [{ code: "dr2dragonblue", stage: 0 }]
    });
};

Families.listExpeditionCodes = function (expedition) {
    var codes = Families.codes.filter(function (code) {
        return Families[code].expeditions.indexOf(String(expedition)) !== -1;
    });
    return cleverapps.createSet(codes);
};

Families.listLazyBundles = function (units) {
    var lazyBundles = {};

    var skin = cleverapps.skins.getSlot("skinName");

    cleverapps.toArray(units || []).forEach(function (unit) {
        var code = unit.code || unit;

        Families[code].units.forEach(function (data, stage) {
            if (!data.lazy || (unit.stage !== undefined && stage !== unit.stage)) {
                return;
            }

            var bundleName = "unit_" + code + "_" + (stage + 1) + "_" + skin;
            if (bundles[bundleName]) {
                lazyBundles[bundleName] = true;
                return;
            }

            bundleName = "unit_" + code + "_" + (stage + 1) + "_main";
            if (bundles[bundleName]) {
                lazyBundles[bundleName] = true;
            }
        });
    });

    return Object.keys(lazyBundles);
};

Families.afterInitialize = function () {
    if (cleverapps.config.debugMode && typeof exports === "undefined") {
        var unknown = cleverapps.dataLoader.load(DataLoader.TYPES.UNKNOWN_UNIT_TEST) || {};
        if (unknown.code !== undefined && unknown.stage !== undefined) {
            delete Families[unknown.code].units[unknown.stage];
        } else if (unknown.code) {
            delete Families[unknown.code];
        }
    }

    Families.codes = Object.keys(Families).filter(function (code) {
        return typeof Families[code] === "object" && ["codes", "types"].indexOf(code) === -1;
    });

    Families.types = {};

    var allExpeditions = [];
    Families.codes.forEach(function (code) {
        var family = Families[code];
        family.code = code;
        family.id = FamiliesIds[code];
        family.expeditions = family.expeditions || ["main"];
        if (!Array.isArray(family.expeditions)) {
            family.expeditions = [family.expeditions];
        }

        if (family.extraBundles && !Array.isArray(family.extraBundles)) {
            family.extraBundles = [family.extraBundles];
        }

        if (family.code === family.images) {
            console.warn("remove " + code + " images");
            delete family.images;
        }

        family.expeditions.forEach(function (expedition) {
            if (expedition !== "all" && allExpeditions.indexOf(expedition) === -1) {
                allExpeditions.push(expedition);
            }
        });

        if (family.type) {
            Families.types[family.type] = true;
        }
    });

    Families.codes.forEach(function (code) {
        var family = Families[code];
        if (family.expeditions.indexOf("all") !== -1) {
            family.expeditions = allExpeditions;
        }
        if (family.extraBundles && family.extraBundles.indexOf("all") !== -1) {
            family.extraBundles = allExpeditions;
        }
    });

    if (typeof window === "undefined" || cleverapps.config.debugMode) {
        Families.codes.forEach(function (code) {
            if (/[^a-zA-Z0-9]/.test(code)) {
                throw "Invalid family code: " + code + ". The family code must match the regex [a-zA-Z0-9]";
            }
        });
    }

    var codesById = {};
    for (var code in FamiliesIds) {
        var id = FamiliesIds[code];
        codesById[id] = code;
    }

    Families.getCodeById = function (id) {
        return codesById[id];
    };

    Families.getIdByCode = function (code) {
        return FamiliesIds[code];
    };
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
