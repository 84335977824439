<template>
    <SidebarMenu :menu="menu" :collapsed="true" :hideToggle="true" :relative="false"/>
    <div class="separator" />
    <div class="zen-current-tag">{{zenCurrentTag}}</div>

    <div class="after-sidebar">
        <div class="tabs-container"/>
        <tabs :tabs="tabs" :currentTab="'profile'"/>
        <div class="container-message-manager">
            <h2 class="activator-text">{{getActivatorText}}</h2>
            <div v-if="!activationCanceled" class="sp-3balls"></div>
        </div>
    </div>
</template>

<script>

import config from "@/config.js";
import Tabs from '@/controls/tabs.vue';
import SidebarMenu from "@/components/sidebar/sidebarmenu";

export default {
    name: "ZenActivator",
    components: {
        SidebarMenu,
        Tabs
    },
    data() {
        return {
            tabs: config.infoTabs,

            activationCanceled: false
        };
    },
    beforeCreate() {
        if (!this.$store.getters.isAuthenticated) {
            this.$router.push({name: 'Login'});
            this.activationCanceled = true;
        }
    },
    created() {
        if (this.$store.getters.isAuthenticated) {
            if (!this.$store.state.tags.all) {
                this.$store.dispatch('fetchTags');
            }
            this.$store.dispatch('fetchUnreadCount');

            setTimeout(function () {
                this.activationCanceled = false;
            }.bind(this), 100);

            setTimeout(function () {
                if (!this.activationCanceled) {
                    this.activateZen();
                }
            }.bind(this), 5000);
        }
    },
    model: {
        isAuthenticated() {
            return this.$store.state.token;
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.token;
        },
        getActivatorText() {
            if (this.$store.state.zen.error) {
                this.activationCanceled = true;
                return this.$store.state.zen.error;
            } else if (!this.activationCanceled) {
                return "Search for next 10 dialogues..."
            }
        },
        zenCurrentTag() {
            return this.$store.state.zen && this.$store.state.zen.tags && this.$store.state.zen.tags.length > 0 ? this.$store.state.zen.tags[0] : 'none';
        },
        menu() {
            var items = [{
                title: 'Search page',
                icon: 'search_light',
                noAnimation: true,
                action: function () {
                    this.activationCanceled = true;
                    this.$router.push({name: 'Dialogues', params: {folder: 'Inbox:all'}});
                }.bind(this)
            }, {
                title: 'Statistic',
                icon: 'statistic',
                noAnimation: true,
                href: 'https://redash.cleverappssg.com/dashboard/crm-responses?p_date=d_this_week',
                external: true
            }];

            var zenGame = {
                title: 'Zen game',
                icon: this.$store.state.zen.game ? this.$store.state.zen.game.split('_')[0].toLowerCase() : 'zen'
            };
            zenGame.child = config.games.map(game => {
                return {
                    title: game,
                    icon: game,
                    count: this.$store.state.count.unread[game],
                    activeChecker: function () {
                        return this.$store.state.zen ? this.$store.state.zen.game : '';
                    }.bind(this),
                    action: function () {
                        this.$store.commit('SET_ZEN_STATE', {
                            active: false,
                            game: game,
                            error: undefined,
                            userIds: [],
                            tags: this.$store.state.zen.tags || []
                        });

                        this.activateZen();
                    }.bind(this)
                }
            });
            items.push(zenGame);

            var zenTag = {
                title: 'Zen tag',
                icon: 'tags_filter'
            };
            var tags = ['none'].concat((this.$store.state.tags.all || []));
            zenTag.child = tags.map(tag => {
                return {
                    title: tag,
                    activeChecker: function () {
                        if (!this.$store.state.zen || !this.$store.state.zen.tags || this.$store.state.zen.tags.length === 0) {
                            return 'none';
                        }
                        return this.$store.state.zen.tags[0];
                    }.bind(this),
                    action: function () {
                        this.$store.commit('SET_ZEN_STATE', {
                            active: false,
                            game: this.$store.state.zen.game,
                            error: undefined,
                            userIds: [],
                            tags: tag === 'none' ? [] : [tag]
                        });

                        this.activateZen();
                    }.bind(this)
                }
            });
            items.push(zenTag);

            return items;
        }
    },
    methods: {
        activateZen() {
            this.activationCanceled = false;
            var game = '';
            if (this.$store.state.zen && this.$store.state.zen.game) {
                game = this.$store.state.zen.game;
            } else {
                game = config.games.filter(function (game) {
                    return game.indexOf('mergecraft') !== -1;
                })[0];
            }

            setTimeout(function () {
                if (!this.activationCanceled) {
                    this.$store.dispatch('activateZen', {
                        person: this.$store.state.respondingPerson,
                        game: game,
                        unreadOnly: true,
                        tags: this.$store.state.zen ? this.$store.state.zen.tags : []
                    });
                }
            }.bind(this), 300);
        }
    }
};
</script>

<style scoped>
.after-sidebar {
    margin-left: 70px;
    height: 100%;
}

.tabs-container {
    overflow-y: auto;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.41);
    border-radius: 8px 8px 0 0;
    width: 98%;
    height: 40%;
    margin-left: 10px;
    min-height: 47px;
}

.activator-text {
    text-align: center;
}

.container-message-manager {
    width: 98%;
    height: 60%;
    box-sizing: border-box;
    color: #565867;
    bottom: 20px;
    display: inline-block;
    box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.41);
    border-radius: 0 0 8px 8px;
    padding: 6px;
    margin: 4px 10px 10px 10px;
}

.zen-current-tag {
    z-index: 10000;
    position: absolute!important;
    left: 0px;
    top: 143px;
    font-size: smaller;
    color: white;
    width: 64px;
    word-break: keep-all;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.separator {
    width: 50px;
    z-index: 1000;
    padding: 0 2px;
    border-bottom: 1px solid #CCC;
    position: absolute !important;
    left: 5px;
    top: 77px;
    color: white;
}

.sp-3balls {
    width: 30px;
    height: 30px;
    clear: both;
    margin: 8px auto;
}
.sp-3balls, .sp-3balls:before, .sp-3balls:after {
    border-radius: 50%;
    background-color: #000;
    width: 18px;
    height: 18px;
    transform-origin: center center;
    display: inline-block;
}
.sp-3balls {
    display: block;
    position: relative;
    background-color: #000;
    opacity: 1;
    -webkit-animation: spScaleAlpha 1s infinite linear;
    animation: spScaleAlpha 1s infinite linear;
}
.sp-3balls:before, .sp-3balls:after {
    content: '';
    position: relative;
    opacity: 0.25;
}
.sp-3balls:before {
    left: 30px;
    top: 0px;
    -webkit-animation: spScaleAlphaBefore 1s infinite linear;
    animation: spScaleAlphaBefore 1s infinite linear;
}
.sp-3balls:after {
    left: -30px;
    top: -23px;
    -webkit-animation: spScaleAlphaAfter 1s infinite linear;
    animation: spScaleAlphaAfter 1s infinite linear;
}
@-webkit-keyframes spScaleAlpha {
    0% { opacity: 1; }
    33% { opacity: 0.25; }
    66% { opacity: 0.25; }
    100% { opacity: 1; }
}
@keyframes spScaleAlpha {
    0% { opacity: 1; }
    33% {  opacity: 0.25; }
    66% { opacity: 0.25; }
    100% {  opacity: 1; }
}
@-webkit-keyframes spScaleAlphaBefore {
    0% { opacity: 0.25; }
    33% { opacity: 1; }
    66% { opacity: 0.25; }
}
@keyframes spScaleAlphaBefore {
    0% { opacity: 0.25; }
    33% { opacity: 1; }
    66% { opacity: 0.25; }
}
@-webkit-keyframes spScaleAlphaAfter {
    33% { opacity: 0.25; }
    66% { opacity: 1; }
    100% { opacity: 0.25; }
}
@keyframes spScaleAlphaAfter {
    33% { opacity: 0.25; }
    66% { opacity: 1; }
    100% { opacity: 0.25; }
}

</style>