/**
 * Created by mac on 9/9/22
 */
var StopHustlemergeFeatures = function () {
    for (var code in ChestListPrize) {
        var chest = ChestListPrize[code];
        for (var stage in chest) {
            delete chest[stage].cinema;
        }
    }

    delete Offers[Offers.TYPES.SOFTFEAST];
    delete Offers[Offers.TYPES.KRAKENFEAST];
    delete Offers[Offers.TYPES.UNDERSEA];

    var nonExistent = [
        Mission.TYPE_SOFTFEAST, Mission.TYPE_CHESTTIME, Mission.TYPE_KRAKENFEAST, Mission.TYPE_SALEPASS,
        Mission.TYPE_UNDERSEA_EXPEDITION, Mission.TYPE_CARAVAN, Mission.TYPE_UNDERSEA2_EXPEDITION,
        Mission.TYPE_RAPUNZEL_EXPEDITION, Mission.TYPE_DRAGONIA2_EXPEDITION, Mission.TYPE_RAPUNZEL2_EXPEDITION,
        Mission.TYPE_EASTER_EXPEDITION, Mission.TYPE_ADVENTURE2_EXPEDITION, Mission.TYPE_DRAGONIA3_EXPEDITION,
        Mission.TYPE_UNDERSEA3_EXPEDITION, Mission.TYPE_RAPUNZEL3_EXPEDITION];

    delete Landmarks.WORLDS.main;

    if (!cleverapps.config.debugMode) {
        delete Offers[Offers.TYPES.EXPEDITION];
        nonExistent.push(Mission.TYPE_DRAGONIA_EXPEDITION);
    }

    Season.AVAILABLE = {
        level: 100
    };

    nonExistent.forEach(function (id) {
        delete Missions[id];
    });
};

var DeletedFamilies = ["sand", "asia", "warcraft", "steampunk", "moonstone",
    "rubiesplant", "coinsplant", "energyplant", "cinema", "thanksgiving", "kraken", "centaur", "gnomes", "shark", "fish", "panda",
    "season0", "season1", "season2", "season3", "season4", "season5", "season6", "season7", "season8", "season9",
    "season10", "season11", "caravanship", "priestess"
];

if (typeof cc === "undefined") {
    module.exports = DeletedFamilies;
}
