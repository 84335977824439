/**
 * Created by Vladislav on 01.08.2024.
 */

const Context = require("./context");

let globalObject;
if (typeof global !== "undefined") {
    globalObject = global;
} else {
    globalObject = window;
}

globalObject.location = globalObject.location || {};

globalObject.bundles = {};
globalObject.connector = {};
globalObject.cleverapps = {
    Environment: {},
    Availables: {
        CLANS: {}
    },
    InitByType: {},
    InitByMeta: {},
    InitByFeature: {}
};

const config = JSON.parse(JSON.stringify(Context.getConfig() || {}));
config.features = config.features || [];
globalObject.cleverapps.config = config;

globalObject.GitManager = function () {};
globalObject.CustomSyncers = {
    registerBySlots: function () {}
};
globalObject.Meta = {};
globalObject.TravelBook = {};
globalObject.Map2d = {};
globalObject.GuideWindow = {};
globalObject.ExpeditionWandsGuideOptions = {};
globalObject.ShopWindow = {
    TABS: {}
};
globalObject.PassLevelsConfig = {};
globalObject.RewardsConfig = {};
globalObject.Forces = {};

globalObject.DataLoader = require("../src/utils/dataloader");

require("../src/utils/utils");
require("../src/utils/push/localpushes");
require("../src/utils/push/localpushessenders");
require("../src/utils/push/yandexpushesmap");
require("../src/utils/abtest/abtest");
require("../src/utils/payments/paymentshistory");
require("../src/utils/lives/lives");

globalObject.Mission = require("../src/utils/missions/mission").Mission;
globalObject.Missions = require("../src/utils/missions/missions").Missions;

globalObject.UnitsLibrary = require("../levels/game/merge/unitslibrary");

const { ChestCreatePrize, ChestListPrize, ChestEnergyPrize } = require("../levels/game/merge/config/chestprizes");

globalObject.ChestCreatePrize = ChestCreatePrize;
globalObject.ChestListPrize = ChestListPrize;
globalObject.ChestEnergyPrize = ChestEnergyPrize;

globalObject.CustomerRecipesIndex = require("../levels/game/merge/customer/customerrecipe").CustomerRecipesIndex;
globalObject.CustomerRecipes = require("../levels/game/merge/customer/customerrecipe").CustomerRecipes;

require("../src/utils/pseudorandom");

globalObject.UnitSyncer = require("../levels/game/merge/sync/unitsyncer");
globalObject.Base80 = require("../src/utils/base80");
globalObject.PropertiesPacker = require("../levels/game/merge/sync/propertiespacker");

globalObject.Offers = require("../src/utils/offers/offers");
require("../src/utils/travel_book/travelbookpages");

globalObject.DeletedFamilies = require("../src/hustlemerge/hustlemergefeaturesconfig");

globalObject.Families = require("../levels/game/merge/config/families");
globalObject.FamiliesHelper = require("../levels/game/merge/config/familieshelper");

Families.initialize();