/**
 * Created by razial on 27.03.2023
 */

TravelBook.PAGES = [
    {
        id: "main",
        prefix: "main",
        slot: Meta.SLOT_MAIN,
        location: "0"
    }
];

TravelBook.SEASONAL_PAGES = [
    {
        id: "xmas",
        prefix: "xm",
        missionType: Mission.TYPE_XMAS_EXPEDITION,
        name: "XmasExpedition",
        location: "xmas",
        energy: true,
        available: {
            level: 7
        },
        feature: "xmas_expedition",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.XMAS,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.XmasPack,
            bundle: "xmas_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_HALLOWEEN,
            base: Offers.TYPES.SNAILFEAST
        }]
    },
    {
        id: "halloween",
        prefix: "hl",
        missionType: Mission.TYPE_HALLOWEEN_EXPEDITION,
        name: "HalloweenExpedition",
        location: "halloween",
        energy: true,
        feature: "halloween_expedition",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.HALLOWEEN,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.Dragonia2Pack,
            bundle: "halloween_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_XMAS,
            base: Offers.TYPES.SNAILFEAST
        }]
    },
    {
        id: "easter",
        prefix: "es",
        missionType: Mission.TYPE_EASTER_EXPEDITION,
        name: "EasterExpedition",
        location: "easter",
        feature: "easter_expedition",
        available: {
            level: 6,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        energy: true,
        noOrdersBuyout: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false
        }],
        offers: [{
            id: Offers.TYPES.EASTER,
            base: Offers.TYPES.EXPEDITION,
            fog: "fog2",
            cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
            reward: RewardsConfig.EasterPack,
            bundle: "easter_offer"
        }]
    },
    {
        id: "china",
        prefix: "ch",
        missionType: Mission.TYPE_CHINA_EXPEDITION,
        name: "ChinaExpedition",
        location: "china",
        energy: true,
        available: {
            level: 7
        },
        feature: "china_expedition",
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.CHINA,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.ChinaPack,
            cooldown: cleverapps.config.debugMode ? "2 minutes" : "12 hours",
            bundle: "china_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_CHINA,
            base: Offers.TYPES.SNAILFEAST
        }]
    },
    {
        id: "blackfriday",
        prefix: "bl",
        missionType: Mission.TYPE_BLACKFRIDAY_EXPEDITION,
        name: "BlackfridayExpedition",
        location: "blackfriday",
        energy: true,
        feature: "blackfriday_expedition",
        available: {
            level: 6
        },
        childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.BLACKFRIDAY,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.BlackFridayPack,
            bundle: "blackfriday_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_BLACKFRIDAY,
            base: Offers.TYPES.SNAILFEAST
        }]
    }
].map(function (page) {
    return Object.assign({
        slot: Meta.EXPEDITION_SLOT1,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1
    }, page);
});

TravelBook.ONESHOT_PAGES = [
    {
        id: "dragonia",
        prefix: "dr",
        missionType: Mission.TYPE_DRAGONIA_EXPEDITION,
        name: "DragoniaExpedition",
        location: "dragonia",
        noOrdersBuyout: true,
        duration: "20 days",
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_EXPEDITION_FEAST],
        offers: [Offers.TYPES.EXPEDITION]
    },
    {
        id: "rapunzel",
        prefix: "rp",
        missionType: Mission.TYPE_RAPUNZEL_EXPEDITION,
        name: "RapunzelExpedition",
        location: "rapunzel",
        duration: "6 days",
        noOrdersBuyout: true,
        energy: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false,
            levels: PassLevelsConfig.rapunzelpass
        }],
        offers: [{
            id: Offers.TYPES.RAPUNZEL,
            base: Offers.TYPES.EXPEDITION,
            fog: "fog2",
            cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
            reward: RewardsConfig.RapunzelPack,
            bundle: "rapunzel_offer"
        }]
    },
    {
        id: "undersea",
        prefix: "sea",
        missionType: Mission.TYPE_UNDERSEA_EXPEDITION,
        name: "UnderseaExpedition",
        location: "undersea",
        noOrdersBuyout: true,
        duration: "20 days",
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_EXPEDITION_FEAST],
        offers: [{
            id: Offers.TYPES.UNDERSEA,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.UnderseaPack,
            bundle: "undersea_offer",
            hero: { code: "underseapack", stage: 0 }
        }]
    },
    {
        id: "dragonia2",
        prefix: "dr2",
        missionType: Mission.TYPE_DRAGONIA2_EXPEDITION,
        name: "Dragonia2Expedition",
        location: "dragonia2",
        noOrdersBuyout: true,
        available: {
            level: 7
        },
        duration: "20 days",
        energy: true,
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.DRAGONIA2,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.Dragonia2Pack,
            bundle: "dragonia_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, Offers.TYPES.SNAILFEAST]
    },
    {
        id: "rapunzel2",
        prefix: "rp2",
        missionType: Mission.TYPE_RAPUNZEL2_EXPEDITION,
        name: "Rapunzel2Expedition",
        location: "rapunzel2",
        noOrdersBuyout: true,
        duration: "8 days",
        energy: true,
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startFog: "fog1",
            rulesOptions: false,
            levels: PassLevelsConfig.rapunzelpass
        }],
        offers: [{
            id: Offers.TYPES.RAPUNZEL2,
            base: Offers.TYPES.EXPEDITION,
            fog: "fog2",
            cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
            reward: RewardsConfig.RapunzelPack,
            bundle: "rapunzel_offer"
        }]
    },
    {
        id: "undersea2",
        prefix: "sea2",
        missionType: Mission.TYPE_UNDERSEA2_EXPEDITION,
        name: "Undersea2Expedition",
        location: "undersea2",
        noOrdersBuyout: true,
        parallelHeroDrop: true,
        duration: "20 days",
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.UNDERSEA2,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.Undersea2Pack,
            bundle: "undersea_offer",
            hero: { code: "sea2pack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_UNDERSEA2,
            base: Offers.TYPES.SNAILFEAST,
            reward: RewardsConfig.SnailPack
        }]
    },
    {
        id: "dragonia3",
        prefix: "dr3",
        missionType: Mission.TYPE_DRAGONIA3_EXPEDITION,
        name: "Dragonia3Expedition",
        location: "dragonia3",
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        duration: "20 days",
        energy: true,
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.DRAGONIA3,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.Dragonia2Pack,
            fog: "fog10",
            bundle: "dragonia_offer",
            hero: { code: "dr2dragonpack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_DRAGONIA3,
            base: Offers.TYPES.SNAILFEAST
        }]
    },
    {
        id: "rapunzel3",
        prefix: "rp3",
        missionType: Mission.TYPE_RAPUNZEL3_EXPEDITION,
        name: "Rapunzel3Expedition",
        location: "rapunzel3",
        duration: "14 days",
        energy: true,
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge", "fairy"]
        },
        childMissions: [{
            type: Mission.TYPE_EXPEDITION_PASS,
            startUnit: { code: "rpcustomermain0a", stage: 1 },
            rulesOptions: false,
            levels: PassLevelsConfig.rapunzel3pass
        }],
        offers: [{
            id: Offers.TYPES.RAPUNZEL3,
            base: Offers.TYPES.EXPEDITION,
            fog: "fog2",
            cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
            reward: RewardsConfig.RapunzelPack,
            bundle: "rapunzel_offer"
        }]
    },
    {
        id: "undersea3",
        prefix: "sea3",
        missionType: Mission.TYPE_UNDERSEA3_EXPEDITION,
        name: "Undersea3Expedition",
        location: "undersea3",
        available: {
            level: 7,
            projectName: ["mergecraft", "wondermerge"]
        },
        duration: "20 days",
        childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
        offers: [{
            id: Offers.TYPES.UNDERSEA3,
            base: Offers.TYPES.EXPEDITION,
            reward: RewardsConfig.Undersea2Pack,
            bundle: "undersea_offer",
            hero: { code: "sea2pack", stage: 0 }
        }, {
            id: Offers.TYPES.SNAILFEAST_UNDERSEA3,
            base: Offers.TYPES.SNAILFEAST,
            reward: RewardsConfig.SnailPack
        }]
    },
    {
        id: "adventure",
        prefix: "adv",
        missionType: Mission.TYPE_ADVENTURE_EXPEDITION,
        name: "AdventureExpedition",
        location: "adventure",
        available: {
            level: 7.5,
            projectName: ["mergecraft", "wondermerge", "fairy", "hustlemerge"]
        },
        duration: "7 days",
        energy: true,
        slot: Meta.EXPEDITION_SLOT4,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4
    },
    {
        id: "adventure2",
        prefix: "adv2",
        missionType: Mission.TYPE_ADVENTURE2_EXPEDITION,
        name: "Adventure2Expedition",
        location: "adventure2",
        available: {
            level: 7.5,
            projectName: ["mergecraft"],
            debugMode: true
        },
        duration: "7 days",
        energy: true,
        slot: Meta.EXPEDITION_SLOT4,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4
    }
].map(function (page) {
    return Object.assign({
        oneShot: true,
        slot: Meta.EXPEDITION_SLOT2,
        semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2
    }, page);
});

TravelBook.PERMANENT_PAGES = [
    {
        id: "collections",
        prefix: "cl",
        name: "CollectionsExpedition",
        location: "collections",
        slot: Meta.EXPEDITION_SLOT3,
        permanent: true,
        gameLevel: true,
        noOrdersBuyout: true,
        available: {
            level: 8.1,
            projectName: ["mergecraft", "fairy", "wondermerge"]
        },
        offers: [
            Offers.TYPES.CLPASS,
            Offers.TYPES.CLCHAIN_SALE,
            Offers.TYPES.CLSUPPLIES_SALE,
            Offers.TYPES.CLPROMOTION_SALE
        ]
    }
];

TravelBook.PAGES = TravelBook.PAGES.concat(TravelBook.SEASONAL_PAGES, TravelBook.ONESHOT_PAGES, TravelBook.PERMANENT_PAGES);

TravelBook.PAGES_BY_ID = TravelBook.PAGES.reduce(function (result, page) {
    result[page.id] = page;
    return result;
}, {});

TravelBook.findPageById = function (expedition) {
    return TravelBook.PAGES_BY_ID[expedition];
};

TravelBook.LIST_IN_ORDER_EXPEDITIONS = function () {
    return TravelBook.PAGES.filter(function (page) {
        return page.id !== "main";
    }).map(function (page) {
        return page.missionType;
    });
};

TravelBook.INIT_EXPEDITIONS = function () {
    var ExpeditionMissionData = Object.assign({}, {
        level: 0,
        duration: "1 day",
        logic: ExpeditionMissionLogic,
        rewardShareId: "bonus_world",
        sideBarJson: false,
        rewardTitle: "ExpeditionRewardWindow.title",
        rewardText: "ExpeditionRewardWindow.description"
    });

    TravelBook.PAGES.forEach(function (page) {
        if (page.id !== "main") {
            page.available = page.available || {};
            page.available.feature = "expedition_" + page.id;
        }

        if (page.missionType !== undefined) {
            page.available = Object.assign({}, cleverapps.Availables.EXPEDITIONS, page.available || {});
            Missions[page.missionType] = Object.assign({}, ExpeditionMissionData, page);
        }
    });
};

if (typeof cc === "undefined") {
    module.exports = TravelBook;
}
