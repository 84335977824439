/**
 * Created by Andrey Popov on 12/16/20.
 */

const localhostGames = ["mergecraft"];
const productionGames = ["riddles", "heroes", "runes", "adventure", "wordsoup", "scramble", "crocword", "olympics", "tripeaks",
    "differences", "mergecraft", "magicwords", "wondermerge", "fairy", "spades", "woodenblock", "zenmatch", "hustlemerge"];
const stagingGames = ["mergecraft", "wondermerge", "fairy", "tripeaks", "crocword", "riddles"];

const folders = [{
    name: "$Inbox",
    icon: "inbox_payers"
}, {
    name: "Inbox",
    icon: "inbox"
}, {
    name: "Spam",
    icon: "spam"
}, {
    name: "Starred",
    icon: "star-on"
}];

const infoTabs = [{ title: "Profile", value: "profile" }, { title: "Subs", value: "subscriptions" }];

const languages = ["de", "en", "ru", "es", "fr", "it", "ja", "nl", "pt", "ar", "ko", "tr", "zh", "pl", "lv"];

const sources = ["amazon", "android", "draugiem", "facebook", "fotostrana", "instant", "ios", "mbga",
    "sp_mbga", "mm", "ok", "vk", "gdcom", "coolmath", "plng", "plinga", "xsolla", "cleverapps", "yandex",
    "iyodo1", "microsoft", "macos", "kongregate", "msstart", "xiaomi", "mygames", "crazy", "samsung", "ton", "rustore", "discord"];

const respondingPersons = ["me", "Anna", "Mikhail", "Anastasia", "Vsevolod", "Bogdan", "Dmitriy", "Alexandrina",
    "Maria Naumova", "Artem Vinokurov", "Denis", "Sergei", "Natalia Sadovaya", "Arseniy Morozov", "Viktoriya", "Alena"];

function getGameFamilies(Families) {
    Families.initialize();
    return Families.codes
        .filter((code) => (!UnitsLibrary.HIDDEN_CODES.concat(UnitsLibrary.HIDDEN_FRESH_CODES).includes(code) || ["landmark"].includes(code))
            && !UnitsLibrary.HIDDEN_TYPES.concat(UnitsLibrary.HIDDEN_FRESH_TYPES).includes(Families[code].type)
            && code !== "barrel" && code !== "unknown"
            && typeof Families[code] === "object")
        .map((code) => ({
            code,
            type: Families[code].type,
            units: Families[code].units,
            expeditions: Families[code].expeditions
        }));
}

const families = {
    mergecraft: getGameFamilies(require("../../../src/mergecraft/families")),
    wondermerge: getGameFamilies(require("../../../src/wondermerge/families")),
    hustlemerge: getGameFamilies(require("../../../src/hustlemerge/families")),
    fairy: getGameFamilies(require("../../../src/fairy/families"))
};

export default {
    games: process.env.VUE_APP_MODE === "production" && productionGames || process.env.VUE_APP_MODE === "staging" && stagingGames || localhostGames,
    apiUrl: process.env.VUE_APP_MODE === "production" && "https://crm.cleverappssg.com" || process.env.VUE_APP_MODE === "staging"
        && "https://crm-staging.labsystech.ru" || "http://localhost:3205",
    folders,
    families,
    sources,
    respondingPersons,
    languages,
    infoTabs
};
