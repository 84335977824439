/**
 * Created by Denis Kuzin on 10 november 2022
 */

var CustomerRecipe = function (recipeTemplate, seed) {
    this.code = recipeTemplate.code;
    this.level = recipeTemplate.level;
    this.customerCode = recipeTemplate.customerCode;

    this.ingredients = this.chooseIngredients(recipeTemplate.ingredients, seed);

    this.order = this.chooseIngredients(recipeTemplate.order, seed);

    if (recipeTemplate.cookingDuration) {
        this.cookingDuration = cleverapps.parseInterval(recipeTemplate.cookingDuration);
    }
};

CustomerRecipe.prototype.chooseIngredients = function (template, seed) {
    var availableCodes = {};
    var items = {};

    if (!template) {
        return [];
    }

    template.forEach(function (unitItem) {
        if (unitItem.ingredient) {
            if (!items[unitItem.ingredient]) {
                items[unitItem.ingredient] = {
                    ingredient: unitItem.ingredient,
                    amount: 0
                };
            }

            items[unitItem.ingredient].amount += unitItem.amount;
            return;
        }

        var chosenCode;

        if (unitItem.code) {
            chosenCode = unitItem.code;
        } else if (unitItem.type) {
            if (!availableCodes[unitItem.type]) {
                availableCodes[unitItem.type] = {};
            }

            if (!availableCodes[unitItem.type][unitItem.stage] || availableCodes[unitItem.type][unitItem.stage].length === 0) {
                availableCodes[unitItem.type][unitItem.stage] = cleverapps.unitsLibrary.listOpenedOfType(unitItem.type);

                if (availableCodes[unitItem.type][unitItem.stage].length === 0) {
                    availableCodes[unitItem.type][unitItem.stage] = cleverapps.unitsLibrary.listCodesByType(unitItem.type).slice(0, 1);
                }
            }

            var codes = availableCodes[unitItem.type][unitItem.stage];

            if (seed === undefined) {
                chosenCode = codes[0];
            } else {
                chosenCode = cleverapps.Random.choose(codes);
            }
            codes.splice(codes.indexOf(chosenCode), 1);
        }

        var chosenStage = unitItem.stage;
        if (unitItem.stages) {
            chosenStage = Math.min(Families[chosenCode].units.length - 1, cleverapps.Random.choose(unitItem.stages));
        }
        if (chosenStage === undefined) {
            chosenStage = Families[chosenCode].units.length - 1;
        }

        if (!items[chosenCode + "_" + chosenCode]) {
            items[chosenCode + "_" + chosenCode] = {
                unit: {
                    code: chosenCode,
                    stage: chosenStage
                },
                amount: 0
            };
        }

        items[chosenCode + "_" + chosenCode].amount += unitItem.amount;
    });

    return Object.values(items);
};

CustomerRecipe.prototype.cook = function (customerUnit, f) {
    f = cleverapps.wait(2, f);

    Map2d.currentMap.customers.takeIngredients(this.ingredients, {
        customer: customerUnit.findComponent(Customer),
        callback: f
    });

    cleverapps.timeouts.setTimeout(f, 300);
};

CustomerRecipe.prototype.listPrize = function () {
    var prizes = [];

    this.order.forEach(function (item) {
        if (item.unit) {
            for (var i = 0; i < item.amount; i++) {
                prizes.push(item.unit);
            }
        } else if (item.ingredient) {
            prizes.push(item);
        }
    });

    return prizes;
};

CustomerRecipe.prototype.isReady = function () {
    return this.ingredients.every(function (ingredient) {
        return Map2d.currentMap.customers.getLackingAmount(ingredient) === 0;
    });
};

CustomerRecipe.prototype.getIngredients = function () {
    return this.ingredients;
};

CustomerRecipe.prototype.getOrder = function () {
    return this.order;
};

CustomerRecipe.prototype.getCookingDuration = function () {
    return this.cookingDuration || 0;
};

CustomerRecipe.prototype.getShoppingList = function () {
    var shoppingList = {
        hardItems: [], softItems: [], totalUnits: 0, totalHard: 0, totalSoft: 0
    };
    this.getIngredients().forEach(function (ingredient) {
        var amount = Map2d.currentMap.customers.getLackingAmount(ingredient);
        var price = this.calcIngredientPrice(ingredient);
        ingredient = cleverapps.clone(ingredient);
        ingredient.amount = amount;

        if (amount) {
            if (price.currency === "soft") {
                shoppingList.softItems.push(ingredient);
                shoppingList.totalSoft += amount * price.price;
            } else {
                shoppingList.hardItems.push(ingredient);
                shoppingList.totalHard += amount * price.price;
            }
        }
    }.bind(this));
    return shoppingList;
};

CustomerRecipe.prototype.calcIngredientPrice = function (ingredient) {
    var currency = "hard";
    var price = 0;

    if (ingredient.unit && ["searesource", "rpresource", "drresource"].indexOf(Families[ingredient.unit.code].type) !== -1) {
        currency = "soft";
    }

    if (!cleverapps.travelBook.getCurrentPage().noOrdersBuyout) {
        if (ingredient.unit && cleverapps.unitsLibrary.isOpened(ingredient.unit)) {
            var type = Families[ingredient.unit.code].type;
            if (type.indexOf("order") !== -1 || type.indexOf("resource") !== -1) {
                price = ingredient.unit.stage === 3 ? 199 : 0;
            } else if (type.indexOf("fruit") !== -1) {
                price = 10;
            } else if (type.indexOf("product0") !== -1) {
                price = 20;
            } else if (type.indexOf("product1") !== -1) {
                price = 50;
            } else if (type.indexOf("product2") !== -1) {
                price = 125;
            } else if (type.indexOf("product3") !== -1) {
                price = 310;
            }
        }

        if (ingredient.unit && Families[ingredient.unit.code].type === "herofood") {
            price = 300;
        }

        if (ingredient.ingredient) {
            price = 3;
        }
    }

    return {
        currency: currency,
        price: price
    };
};

CustomerRecipe.selectTemplate = function (unit, seed, ignoreFilter) {
    if (seed !== undefined) {
        cleverapps.Random.seed(seed);
    }
    var recipes = CustomerRecipes[cleverapps.travelBook.getCurrentPage().id];
    recipes = recipes && recipes[Unit.GetKey(unit)];
    if (!recipes) {
        return;
    }

    recipes = recipes.filter(function (recipe) {
        if (ignoreFilter) {
            return true;
        }

        if (recipe.fog && !Map2d.currentMap.fogs.isOpened(recipe.fog)) {
            return false;
        }

        if (recipe.requiredUnits && !recipe.requiredUnits.every(function (unit) {
            return cleverapps.unitsLibrary.isOpened(unit);
        })) {
            return false;
        }

        if (recipe.opened && !recipe.ingredients.every(function (ingredient) {
            return !ingredient.code || cleverapps.unitsLibrary.isOpened(ingredient);
        })) {
            return false;
        }

        return true;
    });

    return seed === undefined ? recipes[0] : cleverapps.Random.choose(recipes);
};

CustomerRecipe.getIngredientIcon = function (ingredient) {
    if (ingredient.ingredient) {
        return Game.currentGame.harvested.getIngredientIcon(ingredient.ingredient);
    }

    if (ingredient.unit) {
        return UnitView.getUnitImage(ingredient.unit, { preferStatic: true });
    }
};

CustomerRecipe.getIngredientName = function (ingredient) {
    if (ingredient.ingredient) {
        return Game.currentGame.harvested.getIngredientName(ingredient.ingredient);
    }

    if (ingredient.unit) {
        return cleverapps.unitsLibrary.getUnitName(ingredient.unit);
    }
};

var CustomerRecipes = {
    main: {}
};

CustomerRecipes.main = {
    hero: [
        {
            code: "icecream",
            level: 1,
            cookingDuration: "10 seconds",
            ingredients: [
                { ingredient: "wheat", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 }
            ]
        },
        {
            code: "cookies",
            level: 4,
            cookingDuration: "45 seconds",
            ingredients: [
                { ingredient: "candies", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "gingerbread",
            level: 4,
            cookingDuration: "90 seconds",
            ingredients: [
                { ingredient: "candies", amount: 2 },
                { ingredient: "wheat", amount: 3 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "croissant",
            level: 5,
            cookingDuration: "15 minutes",
            ingredients: [
                { ingredient: "candies", amount: 3 },
                { ingredient: "wheat", amount: 4 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "caramelpie",
            level: 6,
            cookingDuration: "5 minutes",
            ingredients: [
                { ingredient: "candies", amount: 2 },
                { ingredient: "wheat", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "bakedapple",
            level: 7,
            cookingDuration: "5 minutes",
            ingredients: [
                { ingredient: "apple", amount: 4 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "applejam",
            level: 7,
            cookingDuration: "15 minutes",
            ingredients: [
                { ingredient: "apple", amount: 5 },
                { ingredient: "wheat", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "caramelapple",
            level: 8,
            cookingDuration: "10 minutes",
            ingredients: [
                { ingredient: "apple", amount: 4 },
                { ingredient: "wheat", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "applepie",
            level: 8,
            cookingDuration: "25 minutes",
            ingredients: [
                { ingredient: "candies", amount: 6 },
                { ingredient: "apple", amount: 2 },
                { ingredient: "wheat", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 1, amount: 1 }
            ]
        },
        {
            code: "strawberryjuice",
            level: 9,
            cookingDuration: "5 minutes",
            ingredients: [
                { ingredient: "strawberry", amount: 4 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "strawberrycake",
            level: 9,
            cookingDuration: "30 minutes",
            ingredients: [
                { ingredient: "candies", amount: 4 },
                { ingredient: "wheat", amount: 3 },
                { ingredient: "strawberry", amount: 5 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 1, amount: 1 }
            ]
        },
        {
            code: "salad",
            level: 10,
            cookingDuration: "18 minutes",
            ingredients: [
                { ingredient: "candies", amount: 2 },
                { ingredient: "strawberry", amount: 6 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "bubblegum",
            level: 11,
            cookingDuration: "20 minutes",
            ingredients: [
                { ingredient: "wheat", amount: 6 },
                { ingredient: "apple", amount: 3 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 1, amount: 1 }
            ]
        },
        {
            code: "granola",
            level: 12,
            cookingDuration: "20 minutes",
            ingredients: [
                { ingredient: "candies", amount: 4 },
                { ingredient: "apple", amount: 5 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 1, amount: 1 }
            ]
        },
        {
            code: "strawberrypie",
            level: 12,
            cookingDuration: "15 minutes",
            ingredients: [
                { ingredient: "strawberry", amount: 4 },
                { ingredient: "candies", amount: 3 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "mushroomcnacks",
            level: 13,
            cookingDuration: "5 minutes",
            ingredients: [
                { ingredient: "mushrooms", amount: 2 },
                { ingredient: "wheat", amount: 3 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "mintcookies",
            level: 13,
            cookingDuration: "18 minutes",
            ingredients: [
                { ingredient: "mushrooms", amount: 2 },
                { ingredient: "candies", amount: 2 },
                { ingredient: "apple", amount: 3 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "stage18",
            level: 14,
            cookingDuration: "30 minutes",
            ingredients: [
                { ingredient: "wheat", amount: 4 },
                { ingredient: "apple", amount: 3 },
                { ingredient: "strawberry", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "stage19",
            level: 15,
            cookingDuration: "15 minutes",
            ingredients: [
                { ingredient: "mushrooms", amount: 2 },
                { ingredient: "strawberry", amount: 2 },
                { ingredient: "apple", amount: 2 }
            ],
            order: [
                { code: "wands", stage: 1, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "stage20",
            level: 16,
            cookingDuration: "20 minutes",
            ingredients: [
                { ingredient: "mushrooms", amount: 2 },
                { ingredient: "candies", amount: 3 },
                { ingredient: "corn", amount: 4 }
            ],
            order: [
                { code: "wands", stage: 0, amount: 1 },
                { code: "wands", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 },
                { code: "coins", stage: 0, amount: 1 }
            ]
        },
        {
            code: "kingfood",
            customerCode: "king",
            level: 5,
            cookingDuration: "0 seconds",
            ingredients: [
                { code: "kingfood", stage: 2, amount: 1 }
            ],
            order: [
                { code: "energy", stage: 2, amount: 1 }
            ]
        },
        {
            code: "dwarffood",
            customerCode: "dwarf",
            level: 5,
            cookingDuration: "0 seconds",
            ingredients: [
                { code: "dwarffood", stage: 2, amount: 1 }
            ],
            order: [
                { code: "energy", stage: 2, amount: 1 }
            ]
        }
    ]
};

var CustomerRecipesIndex = {};

(function () {
    for (var type in CustomerRecipes.main) {
        CustomerRecipesIndex[type] = {};
        CustomerRecipes.main[type].forEach(function (recipe, index) {
            CustomerRecipesIndex[type][recipe.code] = index;

            recipe.order.sort(function (a, b) {
                var isA = a.code === "wands", isB = b.code === "wands";
                return isB - isA || b.stage - a.stage;
            });
        });
    }
}());

if (typeof cc === "undefined") {
    module.exports = {
        CustomerRecipes: CustomerRecipes,
        CustomerRecipesIndex: CustomerRecipesIndex
    };
}