<template>
    <div v-if="isAuthenticated" class="header">
        <input placeholder="lookup in messages" class="search-input" :class="{'search-active':isSearchActive}" ref="searchInput" v-on:keyup.enter="search">
        <div v-if="isSearchActive" class="tag-close" v-on:click="cancelSearch"></div>
        <button class="search-btn primary-btn" @click="search">
            <Icon name="search"/>
        </button>
        <div class="logged">
            <div v-if="respondingPerson && respondingPerson.length > 0" >Logged as: {{ respondingPerson }}</div>
            <button class="logout-btn primary-btn" @click="logout">
                Log out
            </button>
        </div>
    </div>
</template>

<script>
import Icon from '@/controls/icon.vue';

export default {
    name: 'topheader',
    components: {
        Icon
    },
    methods: {
        search() {
            this.$store.dispatch('search', {
                query: this.$refs.searchInput.value
            });
        },
        cancelSearch() {
            this.$refs.searchInput.value = '';
            this.$store.dispatch('search', {
                query: ''
            });
        },
        logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('respondingPerson');
            this.$router.go();
        },
        showNotification(game, message, router) {
            if (!("Notification" in window)) {
                // This browser does not support desktop notification
                return;
            }

            var showNotificationImpl = function(permission) {
                if ("granted" !== permission) {
                    return;
                }

                var notificationText = message.message;
                var tempDiv = document.createElement('div');
                tempDiv.innerHTML = notificationText;
                notificationText = tempDiv.childNodes.length === 0 ? "" : tempDiv.childNodes[0].nodeValue;

                if (notificationText.length > 180) {
                    notificationText = notificationText.substring(0, 180) + '...';
                }

                var notification = new Notification(game + " message from " + message.name, {
                    icon: "https://" + game.split('_')[0].toLowerCase() + "-staging.labsystech.ru/publish/html5-staging/favicon.ico",
                    body: notificationText
                });
                notification.onclick = function () {
                    window.focus();
                    router.push({
                        name: 'UserCard', params: {
                            folder: "Inbox:" + game,
                            userId: message.userid
                        }
                    });
                };
            }

            if (Notification.permission === "granted") {
                showNotificationImpl("granted");
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission(showNotificationImpl);
            }
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.state.token;
        },
        respondingPerson() {
            return this.$store.state.respondingPerson;
        },
        isSearchActive() {
            return this.$store.state.query.length > 2;
        }
    }
}
</script>

<style scoped>
.header {
    border-bottom: 1px solid #e5e5e5;
    padding: 14px 30px 12px;
}

.search-input {
    width: 40%;
    padding: 7px;
    font-size: 16px;
    background: #f1f1f1;
    border: none;
}

.search-active {
    background: #bad4f5;
}

.search-input:focus {
    background: #bad4f5;
}

.search-btn {
    float: left;
    margin-left: 8px;
    margin-right: 2px;
    border-radius: 0 3px 3px 0;
    border: none;
}

.logout-btn {
    border-radius: 0 3px 3px 0;
    border: none;
    background-color: white;
}

.logout-btn:hover {
    background-color: #e8ecec;
    transition: all 0.5s ease;
    border-radius: 20%;
}

.logged {
    float: right;
}

.logged div{
    font-size: small;
    margin-left: 6px;
    color: #5f5f5f;
}

.tag-close {
    vertical-align: middle;
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    font-size: 24px;
    white-space: nowrap;
    margin-left: -20px;
}

.tag-close::before {
    content: '\0000d7';
}

.tag-close:hover {
    font-weight: 900;
}
</style>
